<template>
  <div>
    <b-table-simple class="mb-0" responsive>
      <colgroup>
        <col style="width: 25px" />
      </colgroup>
      <b-thead head-variant="light">
        <b-tr>
          <b-th class="transform-style col-1">
            구분
          </b-th>
          <b-th class="transform-style col-2">
            전기(kWh/m²)
          </b-th>
          <b-th class="transform-style col-3">
            가스(kWh/m²)
          </b-th>
          <b-th class="transform-style col-4">
            열(kWh/m²)
          </b-th>
          <b-th class="transform-style col-5">
            합계(kWh/m²)
          </b-th>
          <b-th class="transform-style col-6">
            백분위(%)
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr>
          <b-td>난방</b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].elechetgqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].gashetgqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].hetghetgqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ (Math.round((resultData[0].elechetgqty + resultData[0].gashetgqty + resultData[0].hetghetgqty) * 100) / 100) | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            <div>
              <div class="progress-container">
                <div class="progress-bar" :style="{ width: resultData[0].allhetgqtyperc + '%' }"></div>
              </div>
              <div class="progress-text">{{ resultData[0].allhetgqtyperc }}%</div>
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>냉방</b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].elecaconqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].gasaconqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].hetgaconqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ (Math.round((resultData[0].elecaconqty + resultData[0].gasaconqty + resultData[0].hetgaconqty) * 100) / 100) | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            <div>
              <div class="progress-container">
                <div class="progress-bar" :style="{ width: resultData[0].allaconqtyperc + '%' }"></div>
              </div>
              <div class="progress-text">{{ resultData[0].allaconqtyperc }}%</div>
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>기저</b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].elecbaseqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].gasbaseqty | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ resultData[0].hetgbaseqty | makeComma }}
          </b-td>

          <b-td v-if="resultData[0]">
            {{ (Math.round((resultData[0].elecbaseqty + resultData[0].gasbaseqty + resultData[0].hetgbaseqty) * 100) / 100) | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            <div>
              <div class="progress-container">
                <div class="progress-bar" :style="{ width: resultData[0].allbaseqtyperc + '%' }"></div>
              </div>
              <div class="progress-text">{{ resultData[0].allbaseqtyperc }}%</div>
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>합계</b-td>
          <b-td v-if="resultData[0]">
            {{ (Math.round((resultData[0].elechetgqty + resultData[0].elecaconqty + resultData[0].elecbaseqty) * 100) / 100) | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ (Math.round((resultData[0].gashetgqty + resultData[0].gasaconqty + resultData[0].gasbaseqty) * 100) / 100) | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{ (Math.round((resultData[0].hetghetgqty + resultData[0].hetgaconqty + resultData[0].hetgbaseqty) * 100) / 100) | makeComma }}
          </b-td>
          <b-td v-if="resultData[0]">
            {{
              (Math.round(
                      (resultData[0].elechetgqty +
                          resultData[0].elecaconqty +
                          resultData[0].elecbaseqty +
                          (resultData[0].gashetgqty + resultData[0].gasaconqty + resultData[0].gasbaseqty) +
                          (resultData[0].hetghetgqty + resultData[0].hetgaconqty + resultData[0].hetgbaseqty)) *
                      100,
                  ) /
                  100)
                  | makeComma
            }}
          </b-td>
          <b-td v-if="resultData[0]">
            <div>
              <div class="progress-container">
                <div class="progress-bar" :style="{ width: resultData[0].allallqtyperc + '%' }"></div>
              </div>
              <div class="progress-text">{{ resultData[0].allallqtyperc }}%</div>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import { BCol, BFormGroup, BProgress, BProgressBar, BRow, BTab, BTableSimple, BTabs, BTbody, BTd, BTh, BThead, BTr } from 'bootstrap-vue';
import { mapGetters } from 'vuex';


export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    BProgress,
    BProgressBar,
    BTabs,
    BTab,

  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resultData: {},
    };
  },
  computed: {
    ...mapGetters({
      mainSelected: 'main/getSelectedCodeData',
    }),
    climateCheck() {
      return this.mainSelected.climateCheck;
    },
    useYySelected() {
      return this.mainSelected.useYyCd.cdId
    }
  },
  watch: {
    climateCheck() {
      this.getMonthEngyData();
    },
    useYySelected() {
      this.getMonthEngyData();
    },
  },
  created() {
    this.getMonthEngyData();
  },
  methods: {
    async getMonthEngyData() {
      this.resultData = await this.$store.dispatch('main/FETCH_ENERGY_USE_DATA', {
        mgmBldPk: this.mgmBldPk,
        useYyCd: this.mainSelected.useYyCd.cdId,
        climateCheck: this.mainSelected.climateCheck, // 기후정규화
      });
    },
  },
};
</script>
<style scoped>
th,
td {
  border: 1px solid #cccccc;
  text-align: center;
}

.transform-style {
  text-transform: none;
}

.col-1 {
  width: 20%;
}
.col-2 {
  width: 15%;
}
.col-3 {
  width: 15%;
}
.col-4 {
  width: 15%;
}
.col-5 {
  width: 15%;
}
.col-6 {
  width: 20%;
}
.totareaStyle {
  font-size: 11px;
  text-align: right;
  color: blue;
}

/* 스타일을 적용할 프로그레스 바 컨테이너 */
.progress-container {
  display: inline-block;
  width: 50%;
  background-color: #ccc;
  overflow: hidden;
}

/* 프로그레스 바 */
.progress-bar {
  width: 46px;
  height: 13px;
  background-color: #4441d4;
}
.progress-text {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  width: 29px;
  height: 11px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4441d4;
}
</style>
