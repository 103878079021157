import { instanceWithAuth } from '@/api';

// HTTP reqeust & response와 관련된 기본 설정을 해줌
const config = {
  url_commCode: '/api/commCode/',
  url_filter: '/api/filter/',
};

const filterAxiosCall = {
  // 호출 함수들
  // 공통코드 정보 호출 - 필터 저장용
  fetchCommCodeData() {
    const commCodeParam = [
      { columnId: 'ANUAL_ST_CD' },
      { columnId: 'REGSTR_GB_CD' },
      { columnId: 'KMA_AREA_CD' },
      { columnId: 'SIDO' },
      { columnId: 'SIGUNGU' },
      { columnId: 'MAIN_PURPS_CD' },
      { columnId: 'INSLT_ST_CD' },
      { columnId: 'TOTAREA_CD' },
      { columnId: 'USE_YY_CD' },
      { columnId: 'CLSF_KIND_CD' },
      { columnId: 'ENGY_KIND_CD' },
      { columnId: 'PLAT_OCP_CD' }, // 대지점유방식
      { columnId: 'RGN_GB_CD' }, // 지역구분코드
      { columnId: 'REP_PURPS_CD' }, // 대표용도
      { columnId: 'DTL_PURPS_CD' }, // 세부용도
      // { columnId: "ENGY_ALL_QTY_CD" },
      // { columnId: "TOTAREA_GB_CD" },
    ];
    return instanceWithAuth.post(`${config.url_commCode}getFilterCodeList`, commCodeParam);
  },

  // 공통코드 정보 호출 - 집단별 통계 요약 조회용
  fetchCommCodeData4Summary() {
    const commCodeParam = [{ columnId: 'ENGY_ALL_QTY_CD' }, { columnId: 'TOTAREA_GB_CD' }];
    return instanceWithAuth.post(`${config.url_commCode}getCommCodeList`, commCodeParam);
  },
  fetchFilterCodeData4Summary() {
    const commCodeParam = [{ columnId: 'ENGY_ALL_QTY_CD' }, { columnId: 'TOTAREA_GB_CD' }];
    return instanceWithAuth.post(`${config.url_commCode}getCommCodeList`, commCodeParam);
  },

  // 시도 정보 호출
  fetchSidoList() {
    return instanceWithAuth.post(`${config.url_commCode}getSidoList`);
  },

  // 시군구 정보 호출
  fetchSigunguList(sidoId) {
    return instanceWithAuth.post(`${config.url_commCode}getSigunguList`, { sidoId });
  },

  // 업종 정보 검색
  fetchOpnsvcList(obj) {
    return instanceWithAuth.post(`${config.url_filter}getOpnsvcData`, obj);
  },

  // 박스플롯 차트 데이터 조회
  fetchFilterBoxplotData(selectedFilterData) {
    return instanceWithAuth.post(`${config.url_filter}getChartData`, selectedFilterData);
  },

  // 건물 목록 엑셀 다운로드
  downloadExcel(selectedFilterData) {
    // 엑셀 다운로드시 responseType을 arraybuffer로 안넘겨주면 error
    return instanceWithAuth.post(`${config.url_filter}filterExcelDown`, selectedFilterData, {
      responseType: 'blob',
    });
  },

  fetchFilterSummaryData(selectedFilterData) {
    return instanceWithAuth.post(`${config.url_filter}getFilterSummaryData`, selectedFilterData);
  },

  // 저장된 필터 내용 조회
  fetchFilterInfoData() {
    return instanceWithAuth.post(`${config.url_filter}getFilterInfoList`);
  },
  // 저장된 필터 내용 조회
  fetchFilterInfoDataNew(payload) {
    return instanceWithAuth.post(`${config.url_filter}getFilterInfoListNew`, payload);
  },

  // 데이터 조회
  fetchFilterData(filterSelected) {
    return instanceWithAuth.post(`${config.url_filter}getFilterData`, filterSelected);
  },

  // 선택된 필터 정보 저장
  saveFilterInfoData(selectedFilterData) {
    return instanceWithAuth.post(`${config.url_filter}saveFilterInfo`, selectedFilterData);
  },
  // 선택된 필터 정보 저장
  saveFilterRenewInfoData(selectedFilterData) {
    return instanceWithAuth.post(`${config.url_filter}saveFilterRenewInfo`, selectedFilterData);
  },
  // 선택된 필터 정보 삭제
  deleteFilterInfoData(selectedFilterData) {
    return instanceWithAuth.post(`${config.url_filter}deleteFilterInfo`, selectedFilterData);
  },

  fetchFilterLoadData(selectedFilterInfo) {
    return instanceWithAuth.post(`${config.url_filter}getFilterLoadData`, selectedFilterInfo);
  },
  // 업종검색 재 조회
  fetchOpnsvcLoadList(selectedFilterInfo) {
    return instanceWithAuth.post(`${config.url_filter}getOpnsvcLoadData`, selectedFilterInfo);
  },
  saveFilterEditOfferData(buildInfoEditOffer) {
    return instanceWithAuth.post(`${config.url_filter}saveFilterEditOffer`, buildInfoEditOffer);
  },
  fetchPerList(payload) {
    return instanceWithAuth.post(`${config.url_filter}getPerList`, payload);
  },
};

// eslint-disable-next-line import/prefer-default-export
export { filterAxiosCall };
