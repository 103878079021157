<template>
  <div class="mainFrame">
    <div>
      <div>
        <h4>
          <li class="fas fa-building"></li>
          <b>건물기본정보</b>
        </h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item class="pointer" @click="goMenuComp('BuildBasicInfo')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'BuildBasicInfo' ? 'active-item' : 'non-active-item'" style="text-decoration:underline;">
              건축물대장
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('BuildFloorOverview')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'BuildFloorOverview' ? 'active-item' : 'non-active-item'">
              층별개요
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('BuildLicenseList')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'BuildLicenseList' ? 'active-item' : 'non-active-item'">
              인허가 리스트
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div class="mt-1">
      <div>
        <h4>
          <li class="fas fa-bolt"></li>
          <b>건물에너지정보</b>
        </h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item class="pointer" @click="goMenuComp('EnergyUseSubInfo')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'EnergyUseSubInfo' ? 'active-item' : 'non-active-item'">
              에너지소비량
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('SimilarBuildingCompare')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'SimilarBuildingCompare' ? 'active-item' : 'non-active-item'">
              유사건물비교
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('EnergyMeterInfo')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'EnergyMeterInfo' ? 'active-item' : 'non-active-item'">
              계량기정보
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('NewEnergy')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'NewEnergy' ? 'active-item' : 'non-active-item'">
              신재생 포텐셜
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div class="mt-1">
      <div>
        <h4><i class="fas fa-address-card"></i> <b>건물입주사정보</b></h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item class="pointer" @click="goMenuComp('TenantInfo')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'TenantInfo' ? 'active-item' : 'non-active-item'">
              입주사 기본정보
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('TenantEngyUse')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'TenantEngyUse' ? 'active-item' : 'non-active-item'">
              입주사 에너지소비량
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('TenantEngyCompare')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'TenantEngyCompare' ? 'active-item' : 'non-active-item'">
              에너지소비량 비교
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div v-if="authRoleId === 'ROLE_ADMIN'" class="mt-1">
      <div>
        <h4><i class="fas fa-tools"></i> <b>건물관리(관리자)</b></h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item class="pointer" @click="goMenuComp('TenantInfoAdmin')">
            <p class="redArea">
              ■
            </p>
            <p :class="selectedItem === 'TenantInfoAdmin' ? 'active-item' : 'non-active-item'">
              입주사별 정보 관리
            </p>
          </b-list-group-item>
          <b-list-group-item class="pointer" @click="goMenuComp('TenantKboss')">
            <p class="redArea">
              ■
            </p>
            <p :class="selectedItem === 'TenantKboss' ? 'active-item' : 'non-active-item'">
              KBOSS 도면입력
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <div v-if="bepaExist" class="mt-1">
      <div>
        <h4><b>건물진단정보</b></h4>
      </div>
      <div>
        <b-list-group flush>
          <b-list-group-item style="cursor: pointer" @click="goMenuComp('pdfBepa')">
            <p class="purpleArea">
              ■
            </p>
            <p :class="selectedItem === 'pdfBepa' ? 'active-item' : 'non-active-item'">
              BEPA 리포트
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>

    <!--    <div class="mt-1">
      <button
        type="button"
        class="myBuildBtn"
        :class="{ violet: KBossYn, white: !KBossYn }"
        @click="toggleKBossYn(KBossModal)"
      >
        {{ KBossYn ? ' KBOSS 도면 확인' : ' KBOSS 도면 등록' }}
      </button>
    </div>-->

    <div class="mt-1">
      <button type="button" class="myBuildBtn" :class="{ purple: bookMark, white: !bookMark }" @click="toggleBookMark(bookMark)">
        <img v-if="bookMark" src="/star_white.png" alt="white star" style="vertical-align:bottom;" />
        <img v-if="!bookMark" src="/star_purple.png" alt="purple star" style="vertical-align:bottom;" />
        {{ bookMark ? ' 내 건물 해지 ' : ' 내 건물 지정' }}
      </button>
    </div>
    <div class="info_offer">
      <button type="button" class="updateSuggest" @click="goMenuComp('buildInfoEditOffer')">
        수정 제안
      </button>
    </div>

    <div class="info_grade">
      <button type="button" class="updateSuggest" @click="goMenuComp('BuildGradeList')">
        건물 맵핑 등급표
      </button>
    </div>

    <div class="mt-1">
      <button type="button" class="bldReportDown" @click="bldReportDown = !bldReportDown">
        검진리포트 다운로드
        <span class="downloadIcon">
          <feather-icon icon="DownloadIcon" />
        </span>
      </button>
    </div>

    <!--    검진리포트 다운로드 모달-->
    <b-modal v-model="bldReportDown" size="sm" title="표시할 정보를 선택해 주세요" centered hide-footer>
      <div><v-select id="useYyCd" v-model="mainSelected.useYyCd" label="cdNm" taggable push-tags :options="commCodeData.USE_YY_CD" /></div>
      <div class="mt-1">
        <b-form-group>
          <b-form-checkbox v-model="reportForm.bldInfo" class="p-0" name="bldInfo" plain checked="" disabled>
            건축물대장(필수)
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="mt-1">
        <b-form-group>
          <b-form-checkbox v-model="reportForm.floorOverview" class="p-0" name="floorOverview" plain>
            층별개요
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="mt-1">
        <b-form-group>
          <b-form-checkbox v-model="reportForm.useYyEngyUse" class="p-0" name="useYyEngyUse" plain>
            연도별 에너지소비량
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="mt-1">
        <b-form-group>
          <b-form-checkbox v-model="reportForm.bldEngyUse" class="p-0" name="bldEngyUseSimilar" plain>
            월별 에너지소비량
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="mt-1">
        <b-form-group>
          <b-form-checkbox v-model="reportForm.bldEngyUseSimilar" class="p-0" name="bldEngyUseSimilar" plain>
            유사 건물군 에너지소비량 비교
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="mt-1">
        <b-form-group>
          <b-form-checkbox v-model="reportForm.tenantEngyUse" class="p-0" name="tenantEngyUse" plain>
            입주사별 에너지소비량 비교
          </b-form-checkbox>
        </b-form-group>
      </div>
      <!-- <div v-if="bepaExist" class="mt-1">
        <b-form-group>
          <b-form-checkbox v-model="reportForm.bepaReport" class="p-0" name="bepaReport" plain checked="">
            BEPA 리포트
          </b-form-checkbox>
        </b-form-group>
      </div> -->
      <div class="d-flex justify-content-center align-items-center">
        <button type="button" class="bldReportJson" @click="pdfExportBuldEngyReportPage">
          검진리포트 다운로드
        </button>
        <button v-if="bepaExist"  type="button" class="bepaReportDownload" @click="bepaPdfReportDownload">
          BEPA 다운로드
        </button>
      </div>
    </b-modal>

    <!--    <b-modal v-model="KBossModal" title="KBOSS 도면 등록" size="lg" centered hide-footer>
      <div>
        <div class="mb-1">
          <h3>건물의 도면 관리</h3>
        </div>
        <div v-show="KBossModalStatus === 'List'">
          <div>
            <b-table :items="items" :fields="fields" responsive="sm">
              <template #cell(selected)="{ rowSelected, item }">
                <template v-if="rowSelected">
                  <label for="chk">
                    <input
                      id="chk"
                      :value="item.kbossfloorplansn"
                      checked
                      type="checkbox"
                      @change="checkList(item.kbossfloorplansn)"
                    />
                  </label>
                </template>
                <template v-else>
                  <input
                    :id="item.kbossfloorplansn"
                    name="delCheck"
                    type="checkbox"
                    @change="checkList(item.kbossfloorplansn)"
                  />
                  <label :for="item.kbossfloorplansn" />
                </template>
              </template>
            </b-table>
          </div>
          <div class="text-right">
            <b-button variant="danger" class="mr-1" @click="kbossDataDelete">
              삭제
            </b-button>
            <b-button variant="primary" @click="kbossModalStatusChange('Create')">
              등록
            </b-button>
          </div>
        </div>
        <div v-show="KBossModalStatus === 'Create'">
          <b-row>
            <b-col md="12">
              <b-form-group label="도면 부가 설명" label-for="fileDesc">
                <b-form-input id="fileDesc" v-model="fileDesc" placeholder="도면 부가 설명을 작성해주세요" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <label for="mapFile">geojson 파일 첨부</label>
              <b-form-file
                id="mapFile"
                v-model="mapFile"
                accept=".geojson"
                placeholder="파일을 선택해주세요"
                drop-placeholder="파일을 드래그 하십시오"
              />
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col class="text-center">
              <b-form-group>
                <b-button variant="warning" class="mr-1" @click="kbossModalStatusChange('List')">
                  취소
                </b-button>
                <b-button variant="primary" class="mr-1" @click="selectFile">
                  저장
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>-->
  </div>
</template>

<script>
import { BListGroup, BListGroupItem, BButton, BModal, BTable, BCol, BForm, BRow, BFormInput, BFormFile, BFormGroup, BFormCheckbox } from 'bootstrap-vue';
import vSelect from 'vue-select';
import axios from 'axios';
import { mapGetters } from 'vuex';
import EventBus from '@/utils/eventBus';
import { ValidationProvider } from 'vee-validate';
import AuthProfile from '~/auth/AuthProfile.vue';

export default {
  components: {
    BFormCheckbox,
    ValidationProvider,
    BFormGroup,
    BForm,
    vSelect,
    BCol,
    AuthProfile,
    BModal,
    BListGroup,
    BListGroupItem,
    BButton,
    BTable,
    BRow,
    BFormInput,
    BFormFile,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bookMark: false,
      bepaExist: false,
      KBossYn: false, // KBOSS 도면 저장 여부
      // KBossModal: false, // Modal 처리
      selectedItem: 'BuildBasicInfo',
      selected: [],
      mapFile: null,
      fileDesc: '', // 도면 부가 설명

      fields: [
        { key: 'selected', label: '선택' },
        { key: 'rownum', label: 'no' },
        { key: 'filename', label: '도면 파일명' },
        { key: 'filedesc', label: '도면 부가설명' },
        { key: 'createdid', label: '등록자' },
        { key: 'createddt', label: '등록일시' },
      ],
      items: [],
      beforeMenu: '',
      bldReportDown: false,
      useYyCd: '',
      reportForm: {
        useYyCd: this.useYyCd,
        bldInfo: true,
        floorOverview: false,
        bldEngyUse: false,
        bldEngyUseSimilar: false,
        tenantEngyUse: false,
        useYyEngyUse: false,
        bepaReport: true,
      },
      reportSendForm: {
        bldPk: this.mgmBldPk,
        useYyCd: '',
        bldInfo: '',
        floorOverview: '',
        bldEngyUse: '',
        bldEngyUseSimilar: '',
        tenantEngyUse: '',
        useYyEngyUse: '',
        bepaReport: '',
      },
      pdfExportPopupWindow: null, // 건물에너지검진리포트 pdf export 를 위한 chart생성용 팝업 윈도우
      // bepaPdfDownloadUrl:null, //bepa리포트 다운로드를 위한 url
    };
  },

  computed: {
    ...mapGetters({
      KBossModalStatus: 'main/getKBossModalStatus',
      authRoleId: 'auth/authRoleId',
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
    }),
  },
  created() {
    EventBus.$on('use-eventbus', () => {
      this.goMenuComp(this.beforeMenu);
    });
  },
  async mounted() {
    // 선택한 건물 내 건물로 등록한 내역 조회
    this.bookMark = await this.$store.dispatch('main/FETCH_BUILD_GET_BOOKMARK_DATA', {
      mgmBldPk: this.mgmBldPk,
    });

    // BEPA 대상 건물 조회
    this.bepaExist = await this.$store.dispatch('main/FETCH_PDF_BEPA_EXIST', {
      mgmBldPk: this.mgmBldPk,
    });

    // 해당 건물에 KBOSS 도면이 저장되어 있는지 확인
    this.KBossYn = await this.$store.dispatch('main/FETCH_BUILD_GET_KBOSS_YN', {
      mgmBldPk: this.mgmBldPk,
    });
  },
  methods: {
    // 메뉴 이동
    async goMenuComp(compName) {
      if (compName === 'SimilarBuildingCompare') {
        this.beforeMenu = this.selectedItem;
      }
      this.selectedItem = compName;
      await this.$store.dispatch('main/FETCH_SELECT_MENU_COMP', compName);
    },
    // // 내 건물 등록
    async toggleBookMark(bookMark) {
      await this.$store.dispatch('main/FETCH_BUILD_SET_BOOKMARK_DATA', {
        mgmBldPk: this.mgmBldPk,
        bookmarkYn: (this.bookMark = !bookMark),
      });
      this.bookMark = !bookMark;
    },
    reportToJson() {
      this.reportForm.useYyCd = this.mainSelected.useYyCd.cdId;
      this.reportSendForm.useYyCd = this.reportForm.useYyCd;
      this.reportSendForm.bldInfo = this.reportForm.bldInfo[0];
      this.reportSendForm.floorOverview = this.reportForm.floorOverview.length > 0 ? this.reportForm.floorOverview[0] : 'N';
      this.reportSendForm.bldEngyUse = this.reportForm.bldEngyUse.length > 0 ? this.reportForm.bldEngyUse[0] : 'N';
      this.reportSendForm.bldEngyUseSimilar = this.reportForm.bldEngyUseSimilar.length > 0 ? this.reportForm.bldEngyUseSimilar[0] : 'N';
      this.reportSendForm.tenantEngyUse = this.reportForm.tenantEngyUse.length > 0 ? this.reportForm.tenantEngyUse[0] : 'N';
      this.reportSendForm.useYyEngyUse = this.reportForm.useYyEngyUse.length > 0 ? this.reportForm.useYyEngyUse[0] : 'N';
      this.reportSendForm.bepaReport = this.reportForm.bepaReport[0];

      console.log(JSON.stringify(this.reportSendForm));
    },

    pdfExportBuldEngyReportPage() {
      // let url = `/buildingEnergyReportPdfExport?mgmBldPk=${this.mgmBldPk}&year=${this.mainSelected.useYyCd.cdId}`; // 건축물대장(총괄/일반):default
      // if (this.reportForm.floorOverview.length > 0 && this.reportForm.floorOverview[0] === 'Y') url = `${url}&florOverView=Y`; //층별개요:선택한경우만
      // if (this.reportForm.useYyEngyUse.length > 0 && this.reportForm.useYyEngyUse[0] === 'Y') url = `${url}&buldEngyUseYy=Y`; //년별에너지소비량:선택한경우만
      // if (this.reportForm.bldEngyUse.length > 0 && this.reportForm.bldEngyUse[0] === 'Y') url = `${url}&buldEngyUseMm=Y`; //월별에너지소비량:선택한경우만
      // if (this.reportForm.bldEngyUseSimilar.length > 0 && this.reportForm.bldEngyUseSimilar[0] === 'Y') url = `${url}&smlrBuldEngyUseCompare=Y`; //유사건물에너지소비량비교
      // if (this.reportForm.tenantEngyUse.length > 0 && this.reportForm.tenantEngyUse[0] === 'Y') url = `${url}&tnntEngyUseCompare=Y`; //입주사별에너지소비량비교
      // if (this.reportForm.bepaReport.length > 0 && this.reportForm.bepaReport[0] === 'Y') url = `${url}&bepaReport=Y`; //BEPA리포트추가제공

      console.log(JSON.stringify(this.reportForm));
      let url = `/buildingEnergyReportPdfExport?mgmBldPk=${this.mgmBldPk}&year=${this.mainSelected.useYyCd.cdId}`; // 건축물대장(총괄/일반):default
      if (this.reportForm.floorOverview) url += '&florOverView=Y'; //층별개요:선택한경우만
      if (this.reportForm.useYyEngyUse) url += '&buldEngyUseYy=Y'; //년별에너지소비량:선택한경우만
      if (this.reportForm.bldEngyUse) url += '&buldEngyUseMm=Y'; //월별에너지소비량:선택한경우만
      if (this.reportForm.bldEngyUseSimilar) url += '&smlrBuldEngyUseCompare=Y'; //유사건물에너지소비량비교
      if (this.reportForm.tenantEngyUse) url += '&tnntEngyUseCompare=Y'; //입주사별에너지소비량비교
      // if (this.reportForm.bepaReport) url += '&bepaReport=Y'; //BEPA리포트추가제공

      this.pdfExportPopupWindow = window.open(url, '_blank');
      if (this.pdfExportPopupWindow) {
        window.addEventListener('message', this.pdfExportWindowReceiveMessage, false); // 부모 창에서 메시지를 받을 때의 핸들러등록
      } else {
        console.log('새 창을 열 수 없습니다.');
      }
      // this.bepaPdfReportDownload(this.mgmBldPk);
    },

    async bepaPdfReportDownload(){ 
        try {
                const bepaPdfDownloadUrl =  "/api/main/pdf/" + this.mgmBldPk + ".pdf"
                console.log(bepaPdfDownloadUrl);
                // PDF 파일 다운로드 요청
                const response = await axios.get(bepaPdfDownloadUrl, {
                    responseType: "blob", // 응답 데이터를 Blob 형태로 설정
                    validateStatus: function (status) {
                        return status === 200; // 성공적인 상태 코드만 허용
                    }
                });

                // Blob 데이터 생성 및 파일 다운로드 링크 생성
                const pdfBlob = new Blob([response.data], { type: "application/pdf" });
                const downloadUrl = window.URL.createObjectURL(pdfBlob);

                // {//새창으로여는방식
                //     window.open(downloadUrl, '_blank');
                // }

                { ///다운로드방식
                    const link = document.createElement("a");
                    link.href = downloadUrl;
                    link.download = "bepaReport.pdf"; // 저장될 파일명 설정
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }

                // Blob URL을 해제하여 메모리 해제
                window.URL.revokeObjectURL(downloadUrl);
              } catch (error) {
                  if (error.response && error.response.status === 404) {
                      console.error("BEPA 리포트 파일을 찾을 수 없습니다:", error);
                      alert("BEPA 리포트 파일을 찾을 수 없습니다."); // 사용자에게 알림
                  } else {
                      console.error("BEPA 리포트 파일 다운로드 실패:", error);
                      alert("BEPA 리포트 파일 다운로드 중 오류가 발생했습니다."); // 사용자에게 알림
                  }
              }
    }



    // pdfExportWindowReceiveMessage(event) {
    //   if (event.data.type === 'chartReportCreateComplite' && event.data.success) { // 자식 창에서 보낸 메시지를 처리
    //     if (this.pdfExportPopupWindow) { // 자식 창에서 완료 메시지를 받으면 팝업을 닫음
    //       this.pdfExportPopupWindow.close();
    //     }
    //   }
    // },
    // beforeDestroy() {
    //   // 컴포넌트가 파괴될 때 이벤트 리스너 제거
    //   window.removeEventListener('message', this.pdfExportWindowReceiveMessage);
    // },
    //     bepaPdfReportDownload(mgmBldPk){
    //             try {
    //                     const bepaPdfDownloadUrl =  "/api/main/pdf/" + mgmBldPk + ".pdf"
    //                     console.log(bepaPdfDownloadUrl);
    // //                     window.open(bepaPdfDownloadUrl , '_blank');
    // // return;
    //                     // PDF 파일 다운로드 요청
    //                     const response = axios.get(bepaPdfDownloadUrl, {
    //                         responseType: "blob", // 응답 데이터를 Blob 형태로 설정
    //                     });

    //                     // Blob 데이터 생성 및 파일 다운로드 링크 생성
    //                     const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    //                     const downloadUrl = window.URL.createObjectURL(pdfBlob);
    //                     const link = document.createElement("a");
    //                     link.href = downloadUrl;
    //                     // link.download = "bepaReport.pdf"; // 저장될 파일명 설정
    //                     document.body.appendChild(link);
    //                     link.click();
    //                     link.remove();

    //                     // Blob URL을 해제하여 메모리 해제
    //                     window.URL.revokeObjectURL(downloadUrl);
    //                 } catch (error) {
    //                     console.error("BEPA 리포트파일 다운로드 실패:", error);
    //                 }
    //     },

    // async toggleKBossYn(KBossModal) {
    //   this.KBossModal = !KBossModal;
    //   // 버튼 누르면 초기화
    //   this.$store.commit('main/SET_KBOSS_MODAL_STATUS', 'List');
    //   // KBOSS 등록 리스트
    //   this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
    //     mgmBldPk: this.mgmBldPk,
    //   });
    // },
    // async kbossModalStatusChange(status) {
    //   // 등록은 건물당 1개의 파일만 등록 가능
    //   if (this.items.length >= 1) {
    //     alert('1개의 파일만 등록할 수 있습니다.');
    //   } else {
    //     this.mapFile = null;
    //     this.fileDesc = '';
    //
    //     await this.$store.commit('main/SET_KBOSS_MODAL_STATUS', status);
    //   }
    // },
    // checkList(rowNum) {
    //   const idx = this.selected.indexOf(rowNum);
    //   if (idx >= 0) {
    //     this.selected.splice(idx, 1);
    //   } else {
    //     this.selected.push(rowNum);
    //   }
    // },
    // async kbossDataDelete() {
    //   if (this.selected.length === 0) {
    //     alert('삭제할 도면을 선택해주세요.');
    //   } else {
    //     const res = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_DELETE', {
    //       mgmBldPk: this.mgmBldPk,
    //       userId: this.$store.state.auth.usrId,
    //       selected: this.selected,
    //     });
    //
    //     if (res) {
    //       alert('삭제완료 되었습니다.');
    //
    //       this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
    //         mgmBldPk: this.mgmBldPk,
    //       });
    //
    //       const checkboxes = document.getElementsByName('delCheck');
    //
    //       checkboxes.forEach((checkbox) => {
    //         // eslint-disable-next-line no-param-reassign
    //         checkbox.checked = false;
    //       });
    //
    //       this.KBossYn = false;
    //     } else {
    //       alert('삭제 권한이 없습니다.');
    //     }
    //   }
    // },
    // selectFile(event) {
    //   const formData = new FormData();
    //
    //   formData.append('userId', this.$store.state.auth.usrId);
    //   formData.append('mgmBldPk', this.mgmBldPk);
    //   formData.append('files', this.mapFile);
    //   formData.append('fileDesc', this.fileDesc);
    //
    //   axios
    //     .post(`/api/main/files`, formData, {
    //       headers: { 'Content-Type': 'multipart/form-data' },
    //     })
    //     .then(async () => {
    //       alert('등록하였습니다.');
    //
    //       // 버튼 누르면 초기화
    //       this.$store.commit('main/SET_KBOSS_MODAL_STATUS', 'List');
    //
    //       this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
    //         mgmBldPk: this.mgmBldPk,
    //       });
    //
    //       this.KBossYn = true;
    //     })
    //     .catch((error) => {
    //       alert(error.message);
    //     });
    // },
  },
};
</script>
<style lang="scss">
.mainFrame .pointer {
  cursor: pointer !important;
  border-width: 0px 0px !important;
  padding: 3px 15px;
}

.purpleArea {
  color: #4441d4;
  display: contents;
}
.redArea {
  color: #ff0000;
  display: contents;
}
.updateSuggest {
  text-decoration: underline;
  color: #82868b;
  border: none;
  background: none;
}
.center {
  text-align: center;
}
.non-active-item {
  display: contents;
}
.active-item {
  display: inline;
  color: #4441d4;
  font-weight: bold;
  text-decoration: underline;
}
.myBuildBtn {
  width: 130px;
  padding: 6px;
  border: 1px solid #4441d4;
  border-radius: 10px;
  font-weight: bold;
}

.info_offer {
  width: 150px;
  padding: 3px 0px 3px 10px;
  color: white;
}

.info_grade {
  padding: 3px 0px 3px 10px;
  width: 150px;
}

.purple {
  background: #4441d4;
  color: white;
}
.white {
  background: white;
  color: #4441d4;
}
.violet {
  background: #862eb2;
  border: 1px solid #862eb2;
  color: white;
}

.mainFrame {
  width: 190px;
  padding: 0px 3px;
}
.bldReportDown {
  width: 130px;
  padding: 2px;
  background: #b60071;
  border: 1px solid #b60071;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  height: 40px;
}
.bldReportJson {
  width: 130px;
  padding: 2px;
  background: #e85c0d;
  border: 1px solid #e85c0d;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  height: 40px;
}
.bepaReportDownload {
  width: 130px;
  margin-left: 10px;
  padding: 2px;
  background: #79b0e0;
  border: 1px solid #479bd8;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  height: 40px;
}

</style>
