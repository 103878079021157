<template>
  <div>
    <div class="mb-2">
      <h3>층별개요
        <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
       </h3> 
    </div>
		<div v-show="loading" class="text-center loadingArea" style="margin-top:15%;">
			<b-spinner class="m-5" label="Busy" variant="dark" />
		</div>
		<div v-show="!loading">
			<b-table
				responsive="sm"
				striped
				:fields="fields"
				:items="
					buildFloorData.map((v) => ({
						flrgbnm: v.flrgbnm,
						flrnonm: v.flrnonm,
						mainpurpsnm: v.mainpurpsnm,
						etcpurps: v.etcpurps,
						area: v.area,
					}))
				"
                table-class="edoc-table"
                thead-class="edoc-thead"
                tbody-class="edoc-tbody"
			/>
		</div>
  </div>
</template>
<script>
import { BTable, BSpinner } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    BTable,
		BSpinner,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
			loading: true,
      fields: [
        {
          key: 'flrgbnm',
          label: '구분',
          class: 'flrgbnm-class',
        },
        {
          key: 'flrnonm',
          label: '층번호',
          class: 'flrnonm-class',
        },
        {
          key: 'mainpurpsnm',
          label: '층용도',
          class: 'mainpurpsnm-class',
        },
        {
          key: 'etcpurps',
          label: '층 기타용도',
          class: 'etcpurps-class',
        },
        {
          key: 'area',
          label: '연면적(㎡)',
          class: 'area-class',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      buildFloorData: 'main/getBuildFloorData',
    }),
  },
  async created() {
    await this.$store.dispatch('main/FETCH_BUILD_FLOOR_DATA', {
      dataCode: 'buildFloorData',
      mgmBldPk: this.mgmBldPk,
    });
		this.loading = false;
  },
};
</script>
<style lang="scss">
.edoc-thead th[class$="-class"]{
    background-color: #f3f2f7 !important;
    padding: 2px 0px 2px 0px;
    text-align: center;
    font-size : 100%;
    font-weight: bold;
}
.edoc-tbody td.flrgbnm-class{
    text-align: center;
    width: 10%;
}
.edoc-tbody td.flrnonm-class{
    text-align: center;
    width: 10%;
}
.edoc-tbody td.mainpurpsnm-class{
    text-align: left;
    width:30%;
}
.edoc-tbody td.etcpurps-class{
    text-align: left;
    width:30%;
}
.edoc-tbody td.area-class{
    text-align: right;
    width:20%;
}

.edoc-tbody td[class$="-class"]{
    padding: 2px 2px;
    border: 1px solid #cccccc;
    border-radius: 0px;
    min-width: 100px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}

</style>