<template>
  <div>
    <e-charts-comp v-if="chartOption.series && chartOption.series.length > 0" :key="JSON.stringify(chartOption)" :chart-option="chartOption"></e-charts-comp>
    <div v-else></div>
  </div>
</template>

<script>
import EChartsComp from '@/components/charts/EChartsComp.vue';

export default {
  name: 'TenantEnergyChart',
  components: {
    EChartsComp,
  },
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOption: {
        title: {
          text: '입주사 에너지 사용량 비중',
          left: 'center',
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          bottom: 10,
          itemWidth: 10,
          itemHeight: 14,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '15%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          interval: 0,
          // min: 0, // X축 최소값
          // max: null, // 나중에 최대값으로 설정
          // splitNumber: 2, // 시작, 중간, 끝 총 3개의 값 (0, 중간, 최대)
          // axisLabel: {
          //   formatter(value) {
          //     if (value >= 1000) {
          //       // 천 단위로 나누고 'K'를 붙임
          //       return `${Number((value / 1000).toFixed(0)).toLocaleString()}K`;
          //     }
          //     // 천 단위 미만은 그대로 표시
          //     return value.toLocaleString();
          //   },
          // },
        },
        yAxis: {
          type: 'category',
          data: ['열', '가스', '전기'],
        },
        series: [],
      },
    };
  },

  watch: {
    chartData: {
      handler(newChartData) {
        this.updateChartOption(newChartData);
      },
      immediate: true,
    },
  },

  methods: {
    updateChartOption(chartData) {
      if (!chartData || chartData.length === 0) {
        this.chartOption.series = [];
        return;
      }
      const allValues = chartData.flatMap((item) => item.use_qty.split(',').map(Number));
      const maxValue = Math.max(...allValues); // 최대값 계산
      // 새로운 chartOption을 할당하여 차트 업데이트
      this.chartOption = {
        ...this.chartOption,
        xAxis: {
          ...this.chartOption.xAxis,
          // max: maxValue, // X축 최대값 설정
        },
        series: chartData
          .map((item) => {
            if (!item || typeof item.use_qty !== 'string') {
              return null;
            }

            return {
              name: item.cd_nm,
              type: 'bar',
              itemStyle: {
                color: item.cd_color,
              },
              label: {
                show: true,
                position: 'insideLeft',
                align: 'left',
                verticalAlign: 'top',

                formatter: (params) =>
                  // 값이 0보다 큰 경우에만 포맷팅된 값을 표시
                  params.value > 0 ? `${params.value.toLocaleString()}` : '',
              },
              data: item.use_qty.split(',').map(Number),
            };
          })
          .filter(Boolean), // null 값 제거
      };
    },
  },
};
</script>
