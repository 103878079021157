<template>
  <div>
    <div class="mb-2">
      <h3>계량기정보
        <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
      </h3>  
    </div>
		<div v-show="loading" class="text-center loadingArea">
			<b-spinner class="m-5" label="Busy" variant="dark" />
		</div>
    <div v-show="!loading" class="metertitle">
      <span class="h4 ">{{ meterType == '1' ? '표제부 매칭 계량기' : '총괄표제부 매칭 계량기' }}</span>
      <b-table class="table-style" striped :items="items" :fields="fields" responsive="sm" 
       table-class="edocmi-table"
       thead-class="edocmi-thead"
       tbody-class="edocmi-tbody"
      />
			<div v-show="isData" class="noData">
        <p>데이터가 존재하지 않습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BSpinner } from 'bootstrap-vue';

export default {
  components: {
    BTable,
		BSpinner,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
    meterType: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
			loading: true,
			isData: false,
      fields: [
        {
          key: 'rownum',
          label: 'No.',
          class: 'rownum-class',
        },
        {
          key: 'engy_esncno',
          label: '계량기 고객번호',
          class: 'engy_esncno-class',
        },
        {
          key: 'engy_nm',
          label: '에너지원 구분',
          class: 'engy_nm-class',
        },
        {
          key: 'use_purps_cd',
          label: '용도',
          class: 'use_purps_cd-class',
        },
        {
          key: 'engy_sply_kik_cd',
          label: '공급기관',
          class: 'engy_sply_kik_cd-class',
        },
      ],
      items: [],
    };
  },
  async created() {
    // 페이지 생성 시 데이터 불러옴
    await this.$store
      .dispatch('main/FETCH_ENERGY_METER_INFO_DATA', {
        dataCode: 'energyMeter',
        meterType: this.meterType, // 1: 일반표제부 계량기, 2: 총괄표제부 계량기
        mgmBldPk: this.mgmBldPk,
      })
      .then(() => {
        this.items = this.$store.state.main.meterList;
				if (this.items.length === 0) this.isData = true;
      })
      .catch(() => {});
			this.loading = false;
  },
};
</script>

<style lang="scss">
.body-style {
  font-family: Helvetica Neue, Arial, sans-serif;
}

.table-style {
  position: relative;
  white-space: nowrap;
}

.metertitle .h4 {
    font-size: 16px;
    font-family: "Noto Sans Korean", sans-serif;
    font-style: normal;
    color: #000;
    line-height: 1;
    vertical-align: middle;
}
.edocmi-thead th[class$="-class"]{
    background-color: #f3f2f7 !important;
    padding: 3px 0px 3px 0px;
    text-align: center;
    font-size : 100%;
    font-weight: bold;
}

.edocmi-tbody td[class$="-class"]{
    padding: 2px 2px;
    border: 1px solid #cccccc;
    border-radius: 0px;
    min-width: 100px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}
.edocmi-tbody td.rownum-class{
    text-align: center;
    width:10%;
}
.edocmi-tbody td.engy_esncno-class{
    text-align: center;
    width:30%;
}
.edocmi-tbody td.engy_nm-class{
    text-align: center;
    width:20%;
}
.edocmi-tbody td.use_purps_cd-class{
    text-align: center;
    width:20%;
}
.edocmi-tbody td.engy_sply_kik_cd-class{
    text-align: center;
    width:20%;
}
</style>