import router from '@/router';
import { cookie } from '@/utils/cookie';
import jwtDefaultConfig from './jwtDefaultConfig';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null;

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // For Refreshing Token
  subscribers = [];

  constructor(axios, jwtOverrideConfig) {
    this.axiosIns = axios.create({
      // timeout: 1000,
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
      },
    });

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      (config) => {
        const accessToken = cookie.getAccessToken();

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = accessToken;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, response } = error;
        const originalRequest = config;

        if (response && response.status === 401) {
          // AccessToken이 만료된 경우 처리
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;

            return this.refreshToken()
              .then((res) => {
                if (!res.data.resultFlag) {
                  // Refresh token도 만료된 경우
                  cookie.delAccessToken();
                  cookie.delRefreshToken();
                  router.replace({ path: 'auth-login' }); // 로그인 페이지로 리다이렉트
                } else {
                  // Access token을 갱신한 경우
                  this.isAlreadyFetchingAccessToken = false;
                  cookie.saveAccessToken(res.data.resultData.accessToken);
                  this.onAccessTokenFetched(res.data.resultData.accessToken);

                  // 갱신된 Access token으로 원래의 요청을 다시 시도
                  originalRequest.headers.Authorization = res.data.resultData.accessToken;
                  return this.axiosIns(originalRequest);
                }
              })
              .catch((e) => {
                console.error(e);
                // 오류 발생 시 로그인 페이지로 리다이렉트
                router.replace({ path: 'auth-login' }).then(() => {
                  this.isAlreadyFetchingAccessToken = false;
                });
              });
          }

          // Access token이 갱신 중인 경우 요청을 대기
          return new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              originalRequest.headers.Authorization = accessToken;
              resolve(this.axiosIns(originalRequest));
            });
          });
        }

        // 다른 에러 처리
        return Promise.reject(error);
      },
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) => callback(accessToken));
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  refreshToken() {
    return this.axiosIns.post(
      this.jwtConfig.refreshEndpoint,
      {
        refreshToken: cookie.getRefreshToken(),
      },
      {
        headers: {
          Authorization: cookie.getAccessToken(),
        },
      },
    );
  }

  /**
   * 인증 처리 포함된 axios 반환
   * @returns 인증 처리 포함된 axios
   */
  getAxiosWithAuth() {
    return this.axiosIns;
  }
}
