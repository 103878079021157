<template>
  <div>
    <b-row class="d-flex justify-content-end">
      <b-col cols="4">
        <b-form-group label-for="useYyCd">
          <v-select id="useYyCd" v-model="useYySelected" label="cdNm" taggable push-tags :options="commCodeData.USE_YY_CD" @input="onClickEnergyUseInfo($event)" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <energy-month-table :mgm-bld-pk="mgmBldPk"></energy-month-table>
      </b-col>
    </b-row>
    <b-row v-if="this.totareaDstrb === 'Y'">
      <b-col cols="12">
        <h6 class="totareaStyle">
          * 총괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값입니다.
        </h6>
      </b-col>
    </b-row>
    <b-row v-if="isSudoQty">
      <b-col cols="8">
        <h5>• 수도사용량</h5>
      </b-col>
      <b-col cols="4" />
      <b-col cols="12">
        <energy-month-sudo-table :mgm-bld-pk="mgmBldPk"></energy-month-sudo-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <echart-bar :mgm-bld-pk="mgmBldPk" :energy-chart-type="engyKind" :use-yy-selected="useYySelected.cdId" />
      </b-col>
      <b-col cols="6">
        <echart-stacked-area :mgm-bld-pk="mgmBldPk" :energy-chart-type="clsfKind" :use-yy-selected="useYySelected.cdId" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import EnergyYearTable from '@/views/building/EnergyUse/periodEngyUse/EnergyYearTable.vue';

import { BCol, BFormGroup, BProgress, BProgressBar, BRow, BTab, BTableSimple, BTabs, BTbody, BTd, BTh, BThead, BTr } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import EchartStackedArea from '~/building/EchartEnergy/EchartStackedArea.vue';
import EchartBar from '~/building/EchartEnergy/EchartBar.vue';
import EnergyMonthTable from '~/building/EnergyUse/periodEngyUse/EnergyMonthTable.vue';
import EnergyMonthSudoTable from '~/building/EnergyUse/periodEngyUse/EnergyMonthSudoTable.vue';

export default {
  components: {
    EnergyMonthSudoTable,
    EnergyMonthTable,
    EchartBar,
    EchartStackedArea,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    BProgress,
    BProgressBar,
    BTabs,
    BTab,

    EnergyYearTable,

    vSelect,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // 넘길 변수 설정
      clsfKind: 'clsfKind',
      engyKind: 'engyKind',
      xAxisData: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      useYySelected: { cdNm: '2017년', cdId: '2017' },
      totareaDstrb: 'N', // Y일경우총 괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값
      isSudoQty: false,

      mgmUpperBldPk: '',
      // 해당 건물의 에너지 사용량 데이터가 있는 년도
      // buildUseYyCd: [],
    };
  },
  computed: {
    ...mapGetters({
      getEnergyUseData: 'main/getEnergyUseData',
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
      buildUseYyCd: 'main/getBuildUseYyData',
    }),
  },
  async mounted() {
    // 페이지 생성 시 데이터 불러옴
    this.useYySelected = await this.mainSelected.useYyCd;
    await this.onClickEnergyUseInfo();
    this.loadTotareaDstrb();

    // 에너지 사용량만 있는 년도 불러옴
    await this.$store.dispatch('main/FETCH_BUILD_USE_YY_CD', { mgmBldPk: this.mgmBldPk });

    // 해당 건물에 총괄 표제부 건물인지 조회
    this.mgmUpperBldPk = await this.$store.dispatch('main/FETCH_MGM_UPPER_BLD_PK', { mgmBldPk: this.mgmBldPk });
  },
  methods: {
    async onClickEnergyUseInfo() {
      await this.$store.dispatch('main/FETCH_ENERGY_USE_DATA', {
        mgmBldPk: this.mgmBldPk,
        useYyCd: this.useYySelected.cdId,
        climateCheck: this.mainSelected.climateCheck, // 기후정규화
      });
      if (this.getEnergyUseData && this.getEnergyUseData.length > 0 && this.getEnergyUseData[0].sudoqty !== 0) {
        this.isSudoQty = true;
      }
    },
    loadTotareaDstrb() {
      // * 총괄표제부의 에너지 소비량을 건물의 연면적으로 배분한 값입니다.
      this.$store
        .dispatch('main/FETCH_TOTAREA_DSTRB_DATA', {
          dataCode: 'totareaDstrb',
          mgmBldPk: this.mgmBldPk,
        })
        .then(() => {
          this.totareaDstrb = this.$store.state.main.totareaDstrb[0].fn_get_eng_div_yn;
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style scoped>
th,
td {
  border: 1px solid #cccccc;
  text-align: center;
}

.transform-style {
  text-transform: none;
}

.col-1 {
  width: 20%;
}
.col-2 {
  width: 15%;
}
.col-3 {
  width: 15%;
}
.col-4 {
  width: 15%;
}
.col-5 {
  width: 15%;
}
.col-6 {
  width: 20%;
}
.totareaStyle {
  font-size: 11px;
  text-align: right;
  color: blue;
}

/* 스타일을 적용할 프로그레스 바 컨테이너 */
.progress-container {
  display: inline-block;
  width: 50%;
  background-color: #ccc;
  overflow: hidden;
}

/* 프로그레스 바 */
.progress-bar {
  width: 46px;
  height: 13px;
  background-color: #4441d4;
}
.progress-text {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  width: 29px;
  height: 11px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4441d4;
}
</style>
