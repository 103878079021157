<template>
  <div>
    <e-charts-comp v-if="chartOption.series && chartOption.series.length > 0" :key="JSON.stringify(chartOption)" :chart-option="chartOption"></e-charts-comp>
    <div v-else>

    </div>
  </div>
</template>

<script>
import EChartsComp from '@/components/charts/EChartsComp.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    EChartsComp,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resultData: [], // 빈 배열로 초기화
      chartValueName: ['난방', '냉방', '기저', '합계'], // 에너지 소비 항목 이름들
      chartOption: {
        legend: {
          bottom: -5,
          itemWidth: 20,
          itemHeight: 14,
        },
        title: {
          text: '연도별-용도별 소비량 변화',
          left: 'center',
          textStyle: {
            fontSize: 14,
          },
        },
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true },
          },
        ],
        grid: {
          left: '30px',
        },
        series: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getEnergyChartData: 'main/getEnergyChartData',
      mainSelected: 'main/getSelectedCodeData',
    }),
    climateCheck() {
      return this.mainSelected.climateCheck;
    },
  },
  watch: {
    climateCheck() {
      this.getYearEngyData();
    },
  },
  mounted() {
    this.getYearEngyData();
  },
  methods: {
    async getYearEngyData() {
      try {
        const result = await this.$store.dispatch('main/FETCH_YEAR_ENGY_DATA', {
          mgmBldPk: this.mgmBldPk,
          climateCheck: this.climateCheck,
        });

        if (Array.isArray(result) && result.length > 0) {
          this.resultData = result;

          // xAxis data 설정
          this.chartOption.xAxis[0].data = this.resultData.map((item) => item.useyy);

          const hetgData = [];
          const aconData = [];
          const baseData = [];
          const totalData = [];

          this.resultData.forEach((item) => {
            baseData.push((item.elecbaseqty + item.gasbaseqty + item.hetgbaseqty).toFixed(2));
            aconData.push((item.elecaconqty + item.gasaconqty + item.hetgaconqty).toFixed(2));
            hetgData.push((item.elechetgqty + item.gashetgqty + item.hetghetgqty).toFixed(2));
            totalData.push(item.allallqty.toFixed(2));
          });

          this.chartOption.series = [
            { name: '기저', type: 'bar', stack: 'advertising', data: baseData, color: '#9DDE8B' },
            { name: '냉방', type: 'bar', stack: 'advertising', data: aconData, color: '#5DEBD7' },
            { name: '난방', type: 'bar', stack: 'advertising', data: hetgData, color: '#1679AB' },
            { name: '합계', type: 'line', data: totalData, color: '#000000' },
          ];

          this.chartOption = { ...this.chartOption };
        } else {
          console.error('유효한 데이터가 없습니다:', result);
          this.resultData = [];
        }
      } catch (error) {
        console.error('데이터 가져오기 실패:', error);
        this.resultData = [];
      }
    },
  },
};
</script>
