<template>
  <div>
    <div class="mb-2">
      <h3>
        [관리자] 입주사별 기본정보
        <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
      </h3>
    </div>
    <!--
    <div class="text-right mb-1">
      <span style="cursor:pointer;" @click="tenantInfoAdd">
        <feather-icon class="mr-15" size="25" color="blue" icon="PlusIcon" />
        신규 입주사 추가
      </span>
    </div>
-->
    <tenant-info-add
      :is-visible="isFormVisible"
      :position="formPosition"
      :mgm-bld-pk="mgmBldPk"
      :mgm-bld-id="mgmBldId"
      @close="isFormVisible = false"
      @refresh="fetchBldTenantList"
    ></tenant-info-add>

    <div>
      <b-table-simple bordered class="rounded-bottom mb-0" hover responsive>
        <colgroup>
          <col style="width: 15%;" />
          <col style="width: 15%;" />
          <col style="width: 15%;" />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col style="width: 12%;" />
        </colgroup>
        <b-thead>
          <b-tr>
            <b-th class="text-center align-middle" rowspan="2">
              업종명
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              상호명
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              업태
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              층번호
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              면적(m²)
            </b-th>
            <b-th class="text-center align-middle" colspan="3">
              연결 계량기 정보
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              관리
            </b-th>
          </b-tr>
          <b-tr>
            <b-th class="text-center align-middle">
              전기
            </b-th>
            <b-th class="text-center align-middle">
              도시가스
            </b-th>
            <b-th class="text-center align-middle">
              지역난방
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="bldTenant in bldTenants" :key="bldTenant.id" class="text-center">
            <b-td>{{ bldTenant.opnsvcnm }}</b-td>
            <b-td>{{ bldTenant.bplcnm }}</b-td>
            <b-td>{{ bldTenant.uptaenm }}</b-td>
            <b-td>{{ bldTenant.flr_no_nm }}</b-td>
            <b-td>{{ bldTenant.sitearea }}</b-td>
            <b-td>{{ bldTenant.elec_use_purps_nms || '-' }}</b-td>
            <b-td>{{ bldTenant.gas_use_purps_nms || '-' }}</b-td>
            <b-td>{{ bldTenant.hetg_use_purps_nms || '-' }}</b-td>
            <b-td class="action-cell">
              <button type="button" class="btn btn-success btn-sm action-button" @click="tenantInfoAdd(bldTenant.id)">
                수정
              </button>
            </b-td>
          </b-tr>
        </b-tbody>
        <!--      <b-tbody >
                <b-tr>
                  <b-td
                    class="text-center align-middle"
                    colspan="9"
                    variant="light"
                  >검색된 데이터가 없습니다.
                  </b-td>
                </b-tr>
              </b-tbody>-->
      </b-table-simple>
    </div>
  </div>
</template>
<script>
import { BTable, BTableSimple, BTbody, BTd, BTh, BThead, BTr, BButton } from 'bootstrap-vue';
import TenantInfoAdd from '~/building/TenantAdmin/TenantInfoAdd.vue';

export default {
  components: {
    TenantInfoAdd,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTableSimple,
    BTd,
    BTable,
    BButton,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormVisible: false,
      mgmBldId: 0,
      bldTenants: [],
      formPosition: {},
    };
  },
  async mounted() {
    await this.fetchBldTenantList();
  },
  methods: {
    tenantInfoAdd(mgmBldId) {
      this.mgmBldId = mgmBldId;
      const rect = event.target.getBoundingClientRect();
      this.formPosition = { top: rect.top + window.scrollY, left: rect.left + rect.width }; // 현재 위치 기반
      this.isFormVisible = true;
    },
    async fetchBldTenantList() {
      try {
        this.bldTenants = await this.$store.dispatch('main/FETCH_BUILD_TENANT_INFO_DATA', {
          mgmBldPk: this.mgmBldPk,
        });
      } catch (error) {
        console.error('Error fetching tenant list:', error);
        // 에러 처리 로직 (예: 사용자에게 알림)
      }
    },
  },
};
</script>

<style scoped>
.action-cell {
  width: 80px; /* 원하는 너비로 조절 */
  padding: 0; /* 불필요한 여백 제거 */
  text-align: center; /* 텍스트와 버튼을 중앙 정렬 */
}

.action-button {
  font-size: 12px; /* 버튼 안의 텍스트 크기 조절 */
  padding: 8px 8px; /* 버튼의 안쪽 여백을 조절 */
  min-width: 60px; /* 버튼의 최소 너비 설정 */
}
table thead th {
  text-transform: none;
}
</style>
