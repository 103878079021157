<template>
  <div>
    <!-- 조건에 따라 다른 컴포넌트를 표시하는 동적 컴포넌트 -->
    <component :is="selectedComponent" :mgm-bld-pk="mgmBldPk" />
  </div>
</template>

<script>
import { mapState } from 'vuex'; // Vuex의 mapState를 import
import BuildBasicInfo from '@/views/building/buildBaiscInfo/BuildBasicInfoComponent';
import BuildFloorOverview from '@/views/building/buildBaiscInfo/BuildFloorOverviewComponent.vue';
import BuildLicenseList from '@/views/building/buildBaiscInfo/BuildLicenseListComponent.vue';
import EnergyUseSubInfo from '@/views/building/EnergyUse/EnergyUseSubInfoComponent.vue';
import EnergyMeterInfo from '@/views/building/EnergyUse/EnergyMeterInfoComponent.vue';
import NewEnergy from '@/views/building/newEnergyUse/NewEnergyComponent.vue';
import pdfBepa from '@/views/building/pdfBepaComponent.vue';
import SimilarBuildingCompare from '@/views/building/SimilarBuildingCompare.vue';
import buildInfoEditOffer from '@/views/building/buildInfoEditOffer/buildInfoEditOffer.vue';
import BuildGradeList from '@/views/building/BuildGradeList.vue';
import TenantInfo from '@/views/building/tenantInfo/TenantInfo.vue';
import TenantEngyUse from '@/views/building/tenantInfo/TenantEngyUse.vue'
import TenantEngyCompare from '@/views/building/tenantInfo/TenantEngyCompare.vue'
import TenantInfoAdmin from '@/views/building/TenantAdmin/TenantInfoAdmin.vue';
import TenantKboss from '@/views/building/TenantAdmin/TenantKboss.vue';

export default {
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      selectMenuComp: (state) => state.main.selectMenuComp,
    }),
    selectedComponent() {
      // 선택된 컴포넌트를 스토어의 상태로부터 가져오거나 기본 컴포넌트로 설정
      const componentMap = {
        BuildBasicInfo,
        BuildFloorOverview,
        BuildLicenseList,
        EnergyUseSubInfo,
        EnergyMeterInfo,
        NewEnergy,
        pdfBepa,
        buildInfoEditOffer,
        SimilarBuildingCompare,
        BuildGradeList,
        TenantInfo,
        TenantEngyUse,
        TenantEngyCompare,
        TenantInfoAdmin,
        TenantKboss,

      };
      return componentMap[this.selectMenuComp] || BuildBasicInfo;
    },
  },
};
</script>
