<template>
  <div class="mt-1">
    <b-table responsive="sm" :items="items" :fields="fields" table-class="edocgd-table" thead-class="edocgd-thead" tbody-class="edocgd-tbody" />
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue';

export default {
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'grade_cls3_cd',
          label: '상세등급',
          class: 'grade_cls3_cd-class',
        },
        {
          key: 'grade_cls3_desc',
          label: '산정 조건 (맵핑 정확도가 높은 케이스)',
          class: 'grade_cls3_desc-class',
        },
      ],
      items: [],
      temp: [],
    };
  },
  async mounted() {
    this.temp = await this.$store.dispatch('main/FETCH_BUILD_GRADE_LIST');

    this.items = this.temp.map((item) => ({
      grade_cls3_cd: item.grade_cls3_cd,
      grade_cls3_desc: item.grade_cls3_desc,
      _rowVariant: item.grade_cls1_cd === '1' ? 'success' : item.grade_cls1_cd === '2' || item.grade_cls1_cd === '3' ? 'warning' : 'danger',
    }));
  },
};
</script>
<style lang="scss">
.edocgd-thead th[class$='-class'] {
  background-color: #f3f2f7 !important;
  padding: 3px 0px 3px 0px;
  text-align: center;
  font-size: 100%;
  font-weight: bold;
}
.edocgd-tbody td.grade_cls3_cd-class {
  text-align: center;
}
.edocgd-tbody td.grade_cls3_desc-class {
  text-align: left;
}

.edocgd-tbody td[class$='-class'] {
  padding: 2px 2px;
  border: 1px solid #cccccc;
  border-radius: 0px;
  min-width: 100px;
  vertical-align: middle; /* 수직 중앙 정렬 */
  white-space: nowrap; /* 줄 바꿈 방지 */
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis; /* 넘치는 텍스트 말줄임 표시 */
}
</style>
