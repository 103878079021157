<template>
  <div>
    <b-card style="height:230px;">
      <app-echart-bar
          :option-data="option"
          style="height:230px;"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: false,
    },
    seriesData1: {
      type: Array,
      required: true,
    },
    seriesData2: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      option: {
        grid: {

        },
        xAxis: [
          {
            type: "category",
            data: ['CASE 1', 'CASE 2', 'CASE 3'],
            axisPointer: {
              type: "shadow",
            },
            triggerEvent: true,
          },
        ],
        yAxis: [
          // 차트의 Y축, 왼쪽
          {
            type: "value",
            name: "kW",
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
          // 차트의 Y축, 오른쪽
          {
            type: "value",
            name: "개",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            name: "용량(kW)",
            stack: "advertising",
            data: this.seriesData2,
          },
          {
            type: "scatter",
            name: "홀 수(개)",
            symbolSize: 15,
            itemStyle: { color: "#ffc107" },
            yAxisIndex: 1,
            //stack: "advertising",
            data: this.seriesData1,
          },
        ],
      },
    }
  },
}
</script>

<style scoped>

</style>
