<template>
  <div v-if="bldSub.length > 0">
    <div class="my-1 d-flex justify-content-between align-items-center">
      <span class="h4 ">표제부 정보</span>
      <span v-if="bldMainYn === 'Y'">
       <b-form-select
         v-model="mgmBldPk"
         :options="bldSubList"
         @change="onChangeBldSub($event)"
       />

      </span>
    </div>

    <div class="table-padding buildBasicInfoRow">
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축물명</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].bldnm ? bldSub[0].bldnm : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축관리번호</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].mgmbldpk ? bldSub[0].mgmbldpk : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축물명칭</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].dongnm ? bldSub[0].dongnm : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>주소</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].plataddr }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건물주용도</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].mainpurpsnm }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>주부속구분</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].mainatchgbnm ? bldSub[0].mainatchgbnm : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>지상층수(층)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].grndflrcnt ? bldSub[0].grndflrcnt : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>지하층수(층)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].ugrndflrcnt ? bldSub[0].ugrndflrcnt : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축면적(m²)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].archarea }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>연면적(m²)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].totarea }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건물높이(m)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].heit ? bldSub[0].heit : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>건폐율(%)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].bcrat }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>대지면적(㎡)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].platarea }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>부속건물면적(m²)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldSub[0]">
            {{ bldSub[0].atchbldarea }}
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BFormGroup, BRow, BFormSelect } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: { BRow, BFormGroup, BCol, BFormSelect },

  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bldSub: [],
      bldSubList: [],
      bldSubSelect: null,
    }
  },
  computed: {
    ...mapGetters({
      bldMainYn: 'main/getBldMainYn',
    }),
  },
  async mounted() {
    await this.fetchInitialData();

    if (this.bldMainYn === 'Y') {
      await this.fetchBldSubList();
      await this.fetchBldSubData(this.bldSubSelect);
      await this.onChangeBldSub(this.bldSubSelect);
    }
  },

  methods: {
    async fetchInitialData() {
      this.bldSub = await this.$store.dispatch('main/FETCH_BUILD_BASIC_ONE_DATA', {
        mgmBldPk: this.mgmBldPk,
      });
    },

    async fetchBldSubList() {
      const data = await this.$store.dispatch('main/FETCH_BUILD_BASIC_ONE_LIST_DATA', {
        mgmBldPk: this.mgmBldPk,
      });

      this.bldSubList = data.map((v) => ({
        text: v.dongnm,
        value: v.mgmbldpk,
      }));

      // if (this.bldSubList.length > 0) {
      //   this.bldSubSelect = this.bldSubList[0].value;
      // }
    },

    async fetchBldSubData(mgmBldPk) {
      this.bldSub = await this.$store.dispatch('main/FETCH_BUILD_BASIC_ONE_DATA', {
        mgmBldPk,
      });
    },

    async onChangeBldSub(val) {
      await this.fetchBldSubData(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.row-style {
  border: 1px solid #f9f9f9;
  border-radius: 3px;
  font-family: "Noto Sans Korean", sans-serif;
  height: 30px;
}

.cols-bg {
    background-color: #f3f2f7 !important;
    min-width: 100px;
    padding: 5px 5px 5px 10px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}

.cols-td {
     min-width: 120px;
     padding: 5px 5px 5px 10px;
     vertical-align: middle;   /* 수직 중앙 정렬 */
     white-space: nowrap;      /* 줄 바꿈 방지 */
     overflow: hidden;         /* 넘치는 텍스트 숨김 */
     text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}

.table-padding {
    padding: 0px 11px 0px 14px;
    border: 1px solid #cccccc;
}

.buildBasicInfoRow .row {
  flex-wrap: nowrap;
}

.my-1.d-flex.justify-content-between.align-items-center .h4 {
    font-size: 16px;
    font-family: "Noto Sans Korean", sans-serif;
    font-style: normal;
    color: #000;
    letter-spacing: -1px;
    line-height: 1;
    vertical-align: bottom;
}
</style>
