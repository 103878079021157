<template>
  <div>
    <b-table-simple class="mb-0" responsive>
      <colgroup>
        <col style="width: 25px" />
      </colgroup>
      <b-thead>
        <b-tr>
          <b-th class="enghead" style="width:10%;">
            구분
          </b-th>
          <b-th class="enghead" colspan="5" style="width:18%;">
            전기(kWh/㎡)
          </b-th>
          <b-th class="enghead" colspan="5" style="width:18%;">
            가스(kWh/㎡)
          </b-th>
          <b-th class="enghead" colspan="5" style="width:18%;">
            열(kWh/㎡)
          </b-th>
          <b-th class="enghead" colspan="5" style="width:18%;">
            합계(kWh/㎡)
          </b-th>
          <b-th class="enghead" colspan="5" style="width:18%;">
            백분위
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="enghead">
            연도
          </b-th>
          <b-th class="enghead">
            2018
          </b-th>
          <b-th class="enghead">
            2019
          </b-th>
          <b-th class="enghead">
            2020
          </b-th>
          <b-th class="enghead">
            2021
          </b-th>
          <b-th class="enghead">
            2022
          </b-th>
          <b-th class="enghead">
            2018
          </b-th>
          <b-th class="enghead">
            2019
          </b-th>
          <b-th class="enghead">
            2020
          </b-th>
          <b-th class="enghead">
            2021
          </b-th>
          <b-th class="enghead">
            2022
          </b-th>
          <b-th class="enghead">
            2018
          </b-th>
          <b-th class="enghead">
            2019
          </b-th>
          <b-th class="enghead">
            2020
          </b-th>
          <b-th class="enghead">
            2021
          </b-th>
          <b-th class="enghead">
            2022
          </b-th>
          <b-th class="enghead">
            2018
          </b-th>
          <b-th class="enghead">
            2019
          </b-th>
          <b-th class="enghead">
            2020
          </b-th>
          <b-th class="enghead">
            2021
          </b-th>
          <b-th class="enghead">
            2022
          </b-th>
          <b-th class="enghead">
            2018
          </b-th>
          <b-th class="enghead">
            2019
          </b-th>
          <b-th class="enghead">
            2020
          </b-th>
          <b-th class="enghead">
            2021
          </b-th>
          <b-th class="enghead">
            2022
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="i in resultData" :key="i.engytype">
          <b-td class="engtd" style="text-align:center;">
            {{ i.engytype }}
          </b-td>
          <b-td class="engtd">
            {{ i.elec_hetg_2018 }}
          </b-td>
          <b-td class="engtd">
            {{ i.elec_hetg_2019 }}
          </b-td>
          <b-td class="engtd">
            {{ i.elec_hetg_2020 }}
          </b-td>
          <b-td class="engtd">
            {{ i.elec_hetg_2021 }}
          </b-td>
          <b-td class="engtd">
            {{ i.elec_hetg_2022 }}
          </b-td>
          <b-td class="engtd">
            {{ i.gas_hetg_2018 }}
          </b-td>
          <b-td class="engtd">
            {{ i.gas_hetg_2019 }}
          </b-td>
          <b-td class="engtd">
            {{ i.gas_hetg_2020 }}
          </b-td>
          <b-td class="engtd">
            {{ i.gas_hetg_2021 }}
          </b-td>
          <b-td class="engtd">
            {{ i.gas_hetg_2022 }}
          </b-td>
          <b-td class="engtd">
            {{ i.hetg_hetg_2018 }}
          </b-td>
          <b-td class="engtd">
            {{ i.hetg_hetg_2019 }}
          </b-td>
          <b-td class="engtd">
            {{ i.hetg_hetg_2020 }}
          </b-td>
          <b-td class="engtd">
            {{ i.hetg_hetg_2021 }}
          </b-td>
          <b-td class="engtd">
            {{ i.hetg_hetg_2022 }}
          </b-td>
          <b-td class="engtd">
            {{ i.sum_hetg_2018 }}
          </b-td>
          <b-td class="engtd">
            {{ i.sum_hetg_2019 }}
          </b-td>
          <b-td class="engtd">
            {{ i.sum_hetg_2020 }}
          </b-td>
          <b-td class="engtd">
            {{ i.sum_hetg_2021 }}
          </b-td>
          <b-td class="engtd">
            {{ i.sum_hetg_2022 }}
          </b-td>
          <b-td class="engtd"> {{ i.all_hetg_perc_2018 }} % </b-td>
          <b-td class="engtd"> {{ i.all_hetg_perc_2019 }} % </b-td>
          <b-td class="engtd"> {{ i.all_hetg_perc_2020 }} % </b-td>
          <b-td class="engtd"> {{ i.all_hetg_perc_2021 }} % </b-td>
          <b-td class="engtd"> {{ i.all_hetg_perc_2022 }} % </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import { BCol, BFormGroup, BProgress, BProgressBar, BRow, BTab, BTableSimple, BTabs, BTbody, BTd, BTh, BThead, BTr } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import EnergyYearChart1 from '~/building/EnergyUse/periodEngyUse/EnergyYearChart1.vue';
import EnergyYearChart2 from '~/building/EnergyUse/periodEngyUse/EnergyYearChart2.vue';
import EnergyYearChart3 from '~/building/EnergyUse/periodEngyUse/EnergyYearChart3.vue';

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    BProgress,
    BProgressBar,
    BTabs,
    BTab,
    EnergyYearChart1,
    EnergyYearChart2,
    EnergyYearChart3,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resultData: {},
    };
  },
  computed: {
    ...mapGetters({
      mainSelected: 'main/getSelectedCodeData',
    }),
    climateCheck() {
      return this.mainSelected.climateCheck;
    },
  },
  watch: {
    climateCheck() {
      this.getYearEngyData();
    },
  },
  created() {
    this.getYearEngyData();
  },
  methods: {
    async getYearEngyData() {
      this.resultData = await this.$store.dispatch('main/FETCH_YEAR_TABLE_ENGY_DATA', {
        mgmBldPk: this.mgmBldPk,
        climateCheck: this.climateCheck,
      });
    },
  },
};
</script>
<style lang="scss">
.mb-0 th.enghead {
  background-color: #f3f2f7;
  border: 1px solid #cccccc;
  padding: 0px 0px 0px 0px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Noto Sans Korean', sans-serif;
  text-transform: none;
}

.mb-0 td.engtd {
  min-width: 30px;
  border: 1px solid #cccccc;
  padding: 0px 5px 0px 5px;
  font-size: 12px;
  font-weight: normal;
  font-family: 'Noto Sans Korean', sans-serif;
  vertical-align: middle; /* 수직 중앙 정렬 */
  text-align: right;
  white-space: nowrap; /* 줄 바꿈 방지 */
  overflow: hidden; /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis; /* 넘치는 텍스트 말줄임 표시 */
}
</style>
