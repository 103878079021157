<template>
  <div>
    <div class="mb-2">
      <h3>
        입주사별 에너지 소비량 비교
        <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
      </h3>
    </div>
    <div>
      <b-row class="d-flex justify-content-end">
        <b-col cols="9">
          <h4>에너지원별 에너지 사용량</h4>
        </b-col>
        <b-col cols="3">
          <b-form-group label-for="useYyCd">
            <v-select id="useYyCd" v-model="mainSelected.useYyCd" label="cdNm" taggable push-tags :options="commCodeData.USE_YY_CD" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="loading" style="text-align: center">
      <b-spinner class="m-5" label="Busy" variant="dark" />
    </div>
    <div v-else>
      <b-row>
        <b-col cols="12">
          <b-table-simple bordered class="rounded-bottom mb-0" hover responsive>
            <colgroup>
              <col style="width: 18%" />
              <col style="width: 18%" />
              <col style="width: 8%" />
              <col style="width: 8%" />
              <col style="width: 8%" />
              <col style="width: 8%" />
              <col style="width: 8%" />
              <col style="width: 8%" />
              <col style="width: 8%" />
              <col style="width: 8%" />
            </colgroup>
            <b-thead>
              <b-tr>
                <b-th rowspan="2" class="text-center align-middle">
                  업종명
                </b-th>
                <b-th rowspan="2" class="text-center align-middle">
                  상호명
                </b-th>
                <b-th colspan="4" class="text-center align-middle">
                  연간 에너지사용량 (kWh/m²)
                </b-th>
                <b-th colspan="4" class="text-center align-middle">
                  건물 에너지사용량 대비 비중 (%)
                </b-th>
              </b-tr>
              <b-tr>
                <b-th class="text-center align-middle">
                  전기
                </b-th>
                <b-th class="text-center align-middle">
                  가스
                </b-th>
                <b-th class="text-center align-middle">
                  지역난방
                </b-th>
                <b-th class="text-center align-middle">
                  합계
                </b-th>
                <b-th class="text-center align-middle">
                  전기
                </b-th>
                <b-th class="text-center align-middle">
                  가스
                </b-th>
                <b-th class="text-center align-middle">
                  지역난방
                </b-th>
                <b-th class="text-center align-middle">
                  합계
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="hasBldTenants">
              <b-tr v-for="bldTenant in bldTenants" :key="bldTenant.id" class="text-center ">
                <b-td>{{ bldTenant.opnsvcnm }}</b-td>
                <b-td>
                  {{ bldTenant.bplcnm }}
                </b-td>
                <b-td>{{ bldTenant.bizelecqty || '-' }}</b-td>
                <b-td>{{ bldTenant.bizgasqty || '-' }}</b-td>
                <b-td>{{ bldTenant.bizhetgqty || '-' }}</b-td>
                <b-td>{{ bldTenant.bizallqty || '-' }}</b-td>
                <b-td>{{ bldTenant.elecperc || '-' }}</b-td>
                <b-td>{{ bldTenant.gasperc || '-' }}</b-td>
                <b-td>{{ bldTenant.hetgperc || '-' }}</b-td>
                <b-td>{{ bldTenant.allperc || '-' }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-center align-middle" colspan="10" variant="light">
                  검색된 데이터가 없습니다.
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BThead, BTbody, BTh, BTr, BTd, BTableSimple, BFormGroup, BSpinner } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';

export default {
  components: {
    BSpinner,
    BFormGroup,
    BTableSimple,
    BTd,
    BTr,
    BTh,
    BTbody,
    BThead,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bldTenants: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
    }),
    hasBldTenants() {
      return Array.isArray(this.bldTenants) && this.bldTenants.length > 0;
    },
  },
  watch: {
    mainSelected: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.useYyCd.cdId) {
          this.getTenantsCompareData();
        }
      },
    },
  },
  async mounted() {
    await this.getTenantsCompareData();
  },
  methods: {
    async getTenantsCompareData() {
      this.bldTenants = await this.$store.dispatch('main/FETCH_BUILD_TENANT_COMPARE_DATA', {
        mgmBldPk: this.mgmBldPk,
        useYyCd: this.mainSelected.useYyCd.cdId,
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.text-center {
  height: 40px;
}
table thead th,
.table tfoot th {
  vertical-align: top;
  text-transform: none;
  font-size: 0.857rem;
  letter-spacing: 0.5px;
}
</style>
