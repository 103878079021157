<template>
  <div>
    <div class="mb-2">
      <h3>▶ [관리자] KBOSS 도면등록</h3>
      <span style="color:red;"><b>※ 해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다.</b></span>
    </div>
    <div>
      <div class="mb-1">
        <h3>건물의 도면 관리</h3>
      </div>
      <div v-show="KBossModalStatus === 'List'">
        <div>
          <b-table :items="items" :fields="fields" responsive="sm">
            <template #cell(selected)="{ rowSelected, item }">
              <template v-if="rowSelected">
                <label for="chk">
                  <input
                    id="chk"
                    :value="item.kbossfloorplansn"
                    checked
                    type="checkbox"
                    @change="checkList(item.kbossfloorplansn)"
                  />
                </label>
              </template>
              <template v-else>
                <input
                  :id="item.kbossfloorplansn"
                  name="delCheck"
                  type="checkbox"
                  @change="checkList(item.kbossfloorplansn)"
                />
                <label :for="item.kbossfloorplansn" />
              </template>
            </template>
          </b-table>
        </div>
        <div class="text-right">
          <b-button variant="danger" class="mr-1" @click="kbossDataDelete">
            삭제
          </b-button>
          <b-button variant="primary" @click="kbossModalStatusChange('Create')">
            등록
          </b-button>
        </div>
      </div>
      <div v-show="KBossModalStatus === 'Create'">
        <b-row>
          <b-col md="12">
            <b-form-group label="도면 부가 설명" label-for="fileDesc">
              <b-form-input id="fileDesc" v-model="fileDesc" placeholder="도면 부가 설명을 작성해주세요" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <label for="mapFile">geojson 파일 첨부</label>
            <b-form-file
              id="mapFile"
              v-model="mapFile"
              accept=".geojson"
              placeholder="파일을 선택해주세요"
              drop-placeholder="파일을 드래그 하십시오"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col class="text-center">
            <b-form-group>
              <b-button variant="warning" class="mr-1" @click="kbossModalStatusChange('List')">
                취소
              </b-button>
              <b-button variant="primary" class="mr-1" @click="selectFile">
                저장
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { BButton, BCol, BFormFile, BFormGroup, BFormInput, BRow, BTable } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BTable,
    BRow,
    BCol,
  },
  data() {
    return {
      fields: [
        { key: 'selected', label: '선택' },
        { key: 'rownum', label: 'no' },
        { key: 'filename', label: '도면 파일명' },
        { key: 'filedesc', label: '도면 부가설명' },
        { key: 'createdid', label: '등록자' },
        { key: 'createddt', label: '등록일시' },
      ],
      items: [],
      fileDesc: '', // 도면 부가 설명
      mapFile: null,
    };
  },
  computed: {
    ...mapGetters({
      KBossModalStatus: 'main/getKBossModalStatus',
    }),
  },
  methods: {
    selectFile(event) {
      const formData = new FormData();

      formData.append('userId', this.$store.state.auth.usrId);
      formData.append('mgmBldPk', this.mgmBldPk);
      formData.append('files', this.mapFile);
      formData.append('fileDesc', this.fileDesc);

      axios
        .post(`/api/main/files`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(async () => {
          alert('등록하였습니다.');

          // 버튼 누르면 초기화
          this.$store.commit('main/SET_KBOSS_MODAL_STATUS', 'List');

          this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
            mgmBldPk: this.mgmBldPk,
          });

          this.KBossYn = true;
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    async kbossDataDelete() {
      if (this.selected.length === 0) {
        alert('삭제할 도면을 선택해주세요.');
      } else {
        const res = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_DELETE', {
          mgmBldPk: this.mgmBldPk,
          userId: this.$store.state.auth.usrId,
          selected: this.selected,
        });

        if (res) {
          alert('삭제완료 되었습니다.');

          this.items = await this.$store.dispatch('main/FETCH_BUILD_KBOSS_MAP_DATA', {
            mgmBldPk: this.mgmBldPk,
          });

          const checkboxes = document.getElementsByName('delCheck');

          checkboxes.forEach((checkbox) => {
            // eslint-disable-next-line no-param-reassign
            checkbox.checked = false;
          });

          this.KBossYn = false;
        } else {
          alert('삭제 권한이 없습니다.');
        }
      }
    },
    async kbossModalStatusChange(status) {
      // 등록은 건물당 1개의 파일만 등록 가능
      if (this.items.length >= 1) {
        alert('1개의 파일만 등록할 수 있습니다.');
      } else {
        this.mapFile = null;
        this.fileDesc = '';

        await this.$store.commit('main/SET_KBOSS_MODAL_STATUS', status);
      }
    },
  },
};
</script>
<style scoped></style>
