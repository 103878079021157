<template>
  <div v-show="isVisible" class="tenant-info-add" :style="{ top: `180px`, left: `${position.left - 266}px` }">
    <validation-observer ref="addForm" v-slot="{ handleSubmit }">
      <h5>▶ 기본정보</h5>
      <b-form @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col cols="12">
            <b-form-group label="업종명:" label-for="opnsvcnm">
              <b-form-input id="opnsvcnm" v-model="formData.opnsvcnm" readonly />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="상호명:" label-for="bplcnm">
              <b-form-input id="bplcnm" v-model="formData.bplcnm" readonly />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="업태:" label-for="uptaenm">
              <b-form-input id="uptaenm" v-model="formData.uptaenm" readonly />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="층번호:" label-for="flrNoNm">
              <validation-provider v-slot="{ errors }" :rules="validationRules.flrNoNm">
                <v-select id="flrNoNm" v-model="selectedFloor" :options="floorOptions" label="flr_no_nm" :reduce="(option) => option.uniqueKey" @input="onFloorSelect" />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="면적(㎡):" label-for="sitearea">
              <validation-provider v-slot="{ errors }" :rules="validationRules.sitearea">
                <b-form-input id="sitearea" v-model="formData.sitearea" />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <h5>▶ 계량기정보</h5>
        <b-row>
          <b-col cols="12">
            <EnergySelect label="전기:" engy-kind-cd="11" :options="bldTenantEngy" :saved-meter="bldSavedMeter" @update:selectedOptions="updateElectricityOptions" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <EnergySelect label="도시가스:" engy-kind-cd="12" :options="bldTenantEngy" :saved-meter="bldSavedMeter" @update:selectedOptions="updateGasOptions" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <EnergySelect label="지역난방:" engy-kind-cd="13" :options="bldTenantEngy" :saved-meter="bldSavedMeter" @update:selectedOptions="updateHeatingOptions" />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary mr-1" type="submit">
            저장
          </button>
          <button class="btn btn-secondary" @click="$emit('close')">
            닫기
          </button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { BBadge, BCol, BForm, BFormGroup, BFormInput, BOverlay, BRow, BListGroup, BListGroupItem } from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';

import { required, numeric } from 'vee-validate/dist/rules';
import vSelect from 'vue-select';
import EnergySelect from '~/building/TenantAdmin/childrenComp/EnergySelect.vue';

extend('required', {
  ...required,
  message: '이 필드는 필수입니다.',
});

extend('numeric', {
  ...numeric,
  message: '숫자만 입력 가능합니다.',
});

export default {
  components: {
    EnergySelect,
    BOverlay,
    BBadge,
    BCardCode,
    BFormInput,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BListGroup,
    BListGroupItem,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    mgmBldPk: {
      type: String,
      required: true,
    },
    mgmBldId: {
      type: Number,
      default: 0,
    },
    position: {
      type: Object,
      default: () => ({ top: 0, left: 0 }),
    },
  },
  data() {
    return {
      formData: {
        opnsvcnm: '',
        bplcnm: '',
        uptaenm: '',
        sitearea: '',
        flrNoNm: '',
        flrGbCd: '',
        flrGbNm: '',
        flrNo: null,
        electricityOptions: [],
        gasOptions: [],
        heatingOptions: [],
        mgmBldId: this.mgmBldId,
        mgmBldPk: this.mgmBldPk,
      },
      bldTenantInfo: [],
      bldTenantEngy: [],
      bldFlrInfo: [],
      bldSavedMeter: [],
      selectedFloor: null,
      selectedOption: '',
      selectedOptions: [],
      validationRules: {
        flrNoNm: { required: true },
        sitearea: { required: true },
        electricityOptions: { required: true },
        gasOptions: { required: true },
        heatingOptions: { required: true },
      },
    };
  },
  computed: {
    floorOptions() {
      return this.bldFlrInfo.map((floor, index) => ({
        ...floor,
        label: floor.flr_no_nm,
        uniqueKey: `${floor.flr_gb_cd}-${floor.flr_no}-${index}`,
      }));
    },
    computedMgmBldId() {
      return this.mgmBldId;
    },
  },
  watch: {
    mgmBldId: {
      immediate: true,
      handler(newValue) {
        this.formData.mgmBldId = newValue;
        if (newValue !== 0) {
          this.fetchTenantInfo();
          this.fetchBuildSaveMeterInfo();
        }
      },
    },
    bldTenantInfo(newVal) {
      if (Array.isArray(newVal) && newVal.length > 0) {
        // 배열인지 확인
        this.formData.opnsvcnm = newVal[0].opnsvcnm || '';
        this.formData.bplcnm = newVal[0].bplcnm || '';
        this.formData.uptaenm = newVal[0].uptaenm || '';
        this.formData.flrNoNm = newVal[0].flrNoNm || '';
        this.formData.sitearea = newVal[0].sitearea || '';
        this.selectedFloor = newVal[0].flr_no_nm || '';
        this.formData.flrNo = newVal[0].flr_no || null;
        this.formData.flrGbCd = newVal[0].flr_gb_cd || '';
        this.formData.flrGbNm = newVal[0].flr_gb_nm || '';
        this.formData.flrNoNm = newVal[0].flr_no_nm || '';
      }
    },
  },
  mounted() {
    this.fetchTenantInfo();
    this.fetchTenantEngyIdData();
    this.fetchBuildFlrInfo();
    this.fetchBuildSaveMeterInfo();
  },
  methods: {
    async fetchTenantInfo() {
      this.bldTenantInfo = await this.$store.dispatch('main/FETCH_BUILD_TENANT_INFO_DATA', {
        mgmBldPk: this.mgmBldPk,
        mgmBldId: this.mgmBldId,
      });
    },
    async fetchTenantEngyIdData() {
      this.bldTenantEngy = await this.$store.dispatch('main/FETCH_BUILD_TENANT_ENGY_ID_DATA', {
        mgmBldPk: this.mgmBldPk,
      });
    },
    async fetchBuildFlrInfo() {
      this.bldFlrInfo = await this.$store.dispatch('main/FETCH_BUILD_FLR_INFO_DATA', {
        mgmBldPk: this.mgmBldPk,
      });
    },
    async fetchBuildSaveMeterInfo() {
      this.bldSavedMeter = await this.$store.dispatch('main/FETCH_BUILD_SAVED_METER_INFO_DATA', {
        mgmBldId: this.computedMgmBldId,
      });
    },
    updateElectricityOptions(selectedOptions) {
      this.formData.electricityOptions = selectedOptions.map((option) => ({
        ...option,
        mgmBldId: this.computedMgmBldId,
      }));
    },
    updateGasOptions(selectedOptions) {
      this.formData.gasOptions = selectedOptions.map((option) => ({
        ...option,
        mgmBldId: this.computedMgmBldId,
      }));
    },
    updateHeatingOptions(selectedOptions) {
      this.formData.heatingOptions = selectedOptions.map((option) => ({
        ...option,
        mgmBldId: this.computedMgmBldId,
      }));
    },
    onFloorSelect(selectedKey) {
      const selected = this.floorOptions.find((option) => option.uniqueKey === selectedKey);
      if (selected) {
        this.formData.flrNoNm = selected.flr_no_nm;
        this.formData.flrGbCd = selected.flr_gb_cd;
        this.formData.flrGbNm = selected.flr_gb_nm;
        this.formData.flrNo = selected.flr_no;
      } else {
        this.formData.flrNoNm = '';
        this.formData.flrGbCd = '';
        this.formData.flrGbNm = '';
        this.formData.flrNo = null;
      }
    },
    async formSubmit() {
      try {
        await this.$store.dispatch('main/FETCH_BUILD_TENANT_INSERT_DATA', {
          formData: {
            ...this.formData,
            mgmBldId: this.computedMgmBldId,
          },
        });
        alert('등록이 완료되었습니다.');
        this.$emit('refresh');
        this.$emit('close');
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('등록 중 오류가 발생했습니다.');
      }
    },
  },
};
</script>

<style scoped>
.tenant-info-add {
  position: fixed;
  width: 300px;
  height: auto;
  background: white;
  border-left: 1px solid #ddd;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}
.card-body {
  padding: 5px;
  border: thin dashed;
}
</style>
