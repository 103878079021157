<template>
  <div>
    <div>
      <energy-year-table :mgm-bld-pk="mgmBldPk"></energy-year-table>
    </div>
    <div class="mt-1">
      <b-row>
        <b-col cols="4">
          <energy-year-chart1 :mgm-bld-pk="mgmBldPk"></energy-year-chart1>
        </b-col>
        <b-col cols="4">
          <energy-year-chart2 :mgm-bld-pk="mgmBldPk"></energy-year-chart2>
        </b-col>
        <b-col cols="4">
          <energy-year-chart3 :mgm-bld-pk="mgmBldPk"></energy-year-chart3>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import EnergyYearChart1 from '@/views/building/EnergyUse/periodEngyUse/EnergyYearChart1';
import EnergyYearChart2 from '@/views/building/EnergyUse/periodEngyUse/EnergyYearChart2';
import EnergyYearChart3 from '@/views/building/EnergyUse/periodEngyUse/EnergyYearChart3';
import EnergyYearTable from '@/views/building/EnergyUse/periodEngyUse/EnergyYearTable.vue';

import {
  BCol,
  BFormGroup,
  BProgress,
  BProgressBar,
  BRow,
  BTab,
  BTableSimple,
  BTabs,
  BTbody,
  BTd,
  BTh,
  BThead,
  BTr,
} from 'bootstrap-vue';

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol,
    BFormGroup,
    BProgress,
    BProgressBar,
    BTabs,
    BTab,
    EnergyYearChart1,
    EnergyYearChart2,
    EnergyYearChart3,
    EnergyYearTable,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
