<template>
  <div>
    <div class="mb-2">
      <h3>건축물대장
      <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
      </h3>  
  </div>
    <build-basic-total :mgm-bld-pk="mgmBldPk"></build-basic-total>
    <build-basic-one :mgm-bld-pk="mgmBldPk"></build-basic-one>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import BuildBasicTotal from '@/views/building/buildBaiscInfo/buildBasicInfo/buildBasicTotal.vue';
import BuildBasicOne from '@/views/building/buildBaiscInfo/buildBasicInfo/buildBasicOne.vue';
import vSelect from 'vue-select';

export default {
  components: {
    BuildBasicOne,
    BuildBasicTotal,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  /*data() {
    return {
      modalBldType: 'block',
    };
  },
  computed: {
    ...mapGetters({
      buildBasicData: 'main/getBuildBasicData',
    }),
  },
  updated() {
    if (this.buildBasicData.length > 0) {
      // 건물 정보 있을때만 업데이트
      this.modalBldType = JSON.stringify(this.buildBasicData[0].regstrkindcd === '1' ? 'none' : 'block');
    }
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.$store.dispatch('main/FETCH_BUILD_BASIC_DATA', {
      dataCode: 'buildBasicData',
      mgmBldPk: this.mgmBldPk,
    });
  },*/
};
</script>
<style>
/*
element.style  {
 --displayTab: modalBldType
}
.nav-justified {
  display: var(--displayTab);
}
*/
</style>
