<template>
  <div v-if="bldMain && bldMain.length > 0">
    <div class="mb-1">
      <span class="h4 ">총괄 표제부 정보</span>
    </div>
    <div class="table-padding buildBasicInfoRow">
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건물명</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].bldnm ? bldMain[0].bldnm : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>건물번호</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].mgmbldpk ? bldMain[0].mgmbldpk : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>외필지수</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].bylotcnt ? bldMain[0].bylotcnt : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group v-if="bldMain[0]">
            대지면적
          </b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].platarea }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>건축면적(㎡)          </b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].archarea }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>건폐율(%)          </b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].bcrat ? bldMain[0].bcrat : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>연면적(㎡)          </b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].totarea }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>용적율(%)          </b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].vlrat ? bldMain[0].vlrat : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>주용도</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].mainpurpsnm }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>주건축물수(개)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].mainbldcnt }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>부속건축물수(개)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].atchbldcnt ? bldMain[0].atchbldcnt : '-' }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>총주차수(대)</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].totpkngcnt ? bldMain[0].totpkngcnt : '-' }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>허가일</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].pmsday }}
          </b-form-group>
        </b-col>
        <b-col cols="2" class="cols-bg">
          <b-form-group>착공일</b-form-group>
        </b-col>
        <b-col cols="4" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].stcnsday }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="row-style">
        <b-col cols="2" class="cols-bg">
          <b-form-group>사용승인일</b-form-group>
        </b-col>
        <b-col cols="10" class="cols-td">
          <b-form-group v-if="bldMain[0]">
            {{ bldMain[0].useaprday }}
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BFormGroup, BRow } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  components: { BRow, BFormGroup, BCol },

  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bldMain: [],
    }
  },
  async mounted() {
    // 페이지 생성 시 데이터 불러옴
    this.bldMain = await this.$store.dispatch('main/FETCH_BUILD_BASIC_TOTAL_DATA', {
      mgmBldPk: this.mgmBldPk,
    });
  },
};
</script>

<style lang="scss" scoped>
.row-style {
  border: 1px solid #f9f9f9;
  border-radius: 3px;
  font-family: "Noto Sans Korean", sans-serif;
  height: 30px;
}

.cols-bg {
    background-color: #f3f2f7 !important;
    min-width: 100px;
    padding: 5px 5px 5px 10px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}

.cols-td {
     min-width: 120px;
     padding: 5px 5px 5px 10px;
     vertical-align: middle;   /* 수직 중앙 정렬 */
     white-space: nowrap;      /* 줄 바꿈 방지 */
     overflow: hidden;         /* 넘치는 텍스트 숨김 */
     text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}

.table-padding {
     padding: 0px 11px 0px 14px;
     border: 1px solid #cccccc;
}

.buildBasicInfoRow .row {
  flex-wrap: nowrap;
}
.mb-1 .h4 {
    font-size: 16px;
    font-family: "Noto Sans Korean", sans-serif;
    font-style: normal;
    color: #000;
    letter-spacing: -1px;
    line-height: 1;
    vertical-align: bottom;
}
</style>
