<template>
  <div>
    <e-charts-comp v-if="chartOption.series && chartOption.series.length > 0" :key="JSON.stringify(chartOption)" :chart-option="chartOption"></e-charts-comp>
    <div v-else>

    </div>
  </div>
</template>

<script>
import EChartsComp from '@/components/charts/EChartsComp.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    EChartsComp,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resultData: [], // 빈 배열로 초기화
      chartValueName: ['전기', '가스', '열', '합계'],
      chartOption: {
        legend: {
          bottom: -5,
          itemWidth: 20,
          itemHeight: 14,
        },
        title: {
          text: '연도별-에너지원별 소비량 변화',
          left: 'center',
          textStyle: {
            fontSize: 14,
          },
        },
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true },
          },
        ],
        grid: {
          left: '30px',
        },
        series: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getEnergyChartData: 'main/getEnergyChartData',
      mainSelected: 'main/getSelectedCodeData',
    }),
    climateCheck() {
      return this.mainSelected.climateCheck;
    },
  },
  watch: {
    climateCheck() {
      this.getYearEngyData();
    },
  },
  mounted() {
    this.getYearEngyData();
  },
  methods: {
    async getYearEngyData() {
      try {
        // series 초기화
        const newChartOption = {
          ...this.chartOption,
          xAxis: [
            {
              type: 'category',
              data: [],
            },
          ],
          series: [],
        };

        // resultData 가져오기
        const result = await this.$store.dispatch('main/FETCH_YEAR_ENGY_DATA', {
          mgmBldPk: this.mgmBldPk,
          climateCheck: this.climateCheck,
        });

        // resultData가 배열인지 확인
        if (Array.isArray(result) && result.length > 0) {
          this.resultData = result;

          // xAxis data 설정
          newChartOption.xAxis[0].data = this.resultData.map((item) => item.useyy);

          const elecData = [];
          const gasData = [];
          const hetgData = [];
          const totalData = [];

          this.resultData.forEach((item) => {
            elecData.push((item.elecbaseqty + item.elecaconqty + item.elechetgqty).toFixed(2));
            gasData.push((item.gasbaseqty + item.gasaconqty + item.gashetgqty).toFixed(2));
            hetgData.push((item.hetgbaseqty + item.hetgaconqty + item.hetghetgqty).toFixed(2));
            totalData.push(item.allallqty.toFixed(2));
          });

          newChartOption.series = [
            { name: '전기', type: 'bar', stack: 'advertising', data: elecData, color: '#E64A45' },
            { name: '가스', type: 'bar', stack: 'advertising', data: gasData, color: '#E6772E' },
            { name: '열', type: 'bar', stack: 'advertising', data: hetgData, color: '#F2C249' },
            { name: '합계', type: 'line', data: totalData, color: '#000000' },
          ];

          // 새로운 chartOption을 할당하여 차트 업데이트
          this.chartOption = newChartOption;
        } else {
          console.error('유효한 데이터가 없습니다:', result);
          this.resultData = []; // 빈 배열로 설정
        }
      } catch (error) {
        console.error('데이터 가져오기 실패:', error);
        this.resultData = []; // 에러 발생 시 빈 배열로 설정
      }
    },
  },
};
</script>
