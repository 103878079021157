<template>
  <div>
    <e-charts-comp v-if="chartOption.series && chartOption.series.length > 0" :key="JSON.stringify(chartOption)" :chart-option="chartOption"></e-charts-comp>
    <div v-else></div>
  </div>
</template>

<script>
import EChartsComp from '@/components/charts/EChartsComp.vue';

export default {
  name: 'TenantEnergyChart',
  components: {
    EChartsComp,
  },
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOption: {
        legend: {
          bottom: 10,
          itemWidth: 20,
          itemHeight: 14,
        },
        title: {
          text: '연도별-에너지원별 소비량 변화',
          left: 'center',
          textStyle: {
            fontSize: 14,
          },
        },
        xAxis: [
          {
            type: 'category',
            data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            axisPointer: {
              type: 'shadow',
            },
            triggerEvent: true,
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'kWh/m²',
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
          {
            type: 'value',
            name: '',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },
        ],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '10%',
          containLabel: true,
        },
        series: [],
      },
    };
  },

  watch: {
    chartData: {
      handler(newChartData) {
        this.updateChartOption(newChartData);
      },
      immediate: true,
    },
  },

  methods: {
    updateChartOption(chartData) {
      if (!chartData || chartData.length === 0) {
        this.chartOption.series = [];
        return;
      }

      // 새로운 chartOption을 할당하여 차트 업데이트
      this.chartOption = {
        ...this.chartOption,
        series: chartData
          .map((item) => {
            if (!item || typeof item.use_qty !== 'string') {
              return null;
            }

            return {
              name: item.cd_nm,
              type: 'bar',
              stack: 'advertising',
              itemStyle: {
                color: item.cd_color,
              },
              data: item.use_qty.split(',').map(Number),
            };
          })
          .filter(Boolean), // null 값 제거
      };
    },
  },
};
</script>
