import { filterAxiosCall } from '@api/filter';
import { utils } from '@/utils/commUtils';

export default {
  // 공통코드 데이터 조회
  FETCH_COMM_CODE_DATA({ commit }) {
    filterAxiosCall.fetchCommCodeData().then((res) => {
      if (res.data.resultFlag) {
        const { resultData } = res.data;
        commit('SET_COMM_CODE_DATA', resultData);

        // 에너지원(ENGY_KIND_CD)과 사용용도(CLSF_KIND_CD) 공통코드에서 '전체(ALL)' 코드는 제거하기
        const removeKey = ['ENGY_KIND_CD', 'CLSF_KIND_CD'];
        removeKey.forEach((remove) => {
          resultData[remove] = resultData[remove].filter((comm) => comm.cdId !== 'All');
        });

        // default value 설정
        const defaultKeyInfoList = utils.fnFindDefaultValue4Map(resultData, 'defValYn');
        if (defaultKeyInfoList !== undefined && defaultKeyInfoList !== null) {
          Object.keys(defaultKeyInfoList).forEach((element) => {
            // default값 설정
            commit('SET_INIT_SELECTED_COMM_CODE', {
              key: element,
              value: defaultKeyInfoList[element],
            });
          });
        }
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
      } else {
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      }
    });
  },
  // 22/10/05 신규 생성
  FETCH_COMM_CODE_RENEW_DATA({ commit }) {
    filterAxiosCall.fetchCommCodeData().then((res) => {
      if (res.data.resultFlag) {
        const { resultData } = res.data;
        commit('SET_FILTER_COMM_CODE_DATA', resultData);

        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
      } else {
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      }
    });
  },
  // 공통코드 데이터 조회
  async FETCH_COMM_CODE_DATA_SUMMARY({ commit, dispatch, state }) {
    await filterAxiosCall.fetchCommCodeData4Summary().then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BOXPLOT_DATA', {});
        commit('SET_COMM_CODE_DATA', res.data.resultData);

        const defaultKeyInfoList = utils.fnFindDefaultValue4Map(res.data.resultData, 'defValYn');

        const defaultKeyObj = {
          engyAllQtyCd: defaultKeyInfoList.ENGY_ALL_QTY_CD,
          totareaGbCd: defaultKeyInfoList.TOTAREA_GB_CD,
        };

        Object.keys(defaultKeyObj).forEach(async (element) => {
          // default값 설정
          await commit('SET_INIT_FILTER_FETCHED_INFO', {
            key: element,
            value: defaultKeyObj[element],
          });
        });

        const selected = state.filterFetchedInfo;
        dispatch('FETCH_FILTER_SUMMARY_DATA', selected);
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
      } else {
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      }
    });
  },

  // 시도 데이터 조회
  FETCH_SIDO_LIST({ commit, dispatch, state }) {
    filterAxiosCall
      .fetchSidoList()
      .then((res) => {
        if (res.data.resultFlag) {
          commit('SET_SIDO_LIST', res.data.resultData);

          // default값 설정
          const defaultSido = utils.fnFindDefaultValue4Array(res.data.resultData, 'defValYn');

          if (defaultSido !== undefined && defaultSido !== null) {
            // defaultValue가 설정되어 있는 경우
            commit('SET_INIT_SELECTED_COMM_CODE', {
              key: 'SIDO_ID',
              value: defaultSido,
            });
            // 시군구 데이터 조회
            const { sidoId } = state.saveFilterInfo.SIDO_ID[0];
            dispatch('FETCH_SIGUNGU_LIST', sidoId);
          }
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        } else {
          commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // 시군구 데이터 조회
  FETCH_SIGUNGU_LIST({ commit }, sidoId) {
    // 선택된 시군구 정보 초기화
    commit('SET_INIT_SELECTED_ADDR', { key: 'SIGUNGU_ID', value: [] });

    if (sidoId !== null) {
      filterAxiosCall
        .fetchSigunguList(sidoId)
        .then((res) => {
          if (res.data.resultFlag) {
            // 정상 조회 된 경우
            // 시군구 데이터 설정
            commit('SET_SIGUNGU_LIST', res.data.resultData);
            commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
          } else {
            // 조회 중 오류 발생한 경우
            commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
          }
        })
        .catch((error) => {
          commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
          console.log(error);
        });
    } else {
      commit('SET_SIGUNGU_LIST', []);
    }
  },

  // 업종 데이터 조회(팝업)
  async FETCH_OPNSVC_LIST({ commit }, obj) {
    await filterAxiosCall.fetchOpnsvcList(obj).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_OPNSVC_LIST', res.data.resultData);
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
      } else {
        commit('SET_RESULT', { resultFlag: false, resultMsg: res.data.resultMsg });
      }
    });
  },

  // 집단별 통계 요약 테이블 조회
  FETCH_FILTER_SUMMARY_DATA({ commit }, selectedFilterData) {
    // 오버레이 보이기
    commit('SET_SHOW_OVERLAY', true);
    filterAxiosCall
      .fetchFilterSummaryData(selectedFilterData)
      .then((res) => {
        if (res.data.resultFlag) {
          commit('SET_DATA_SUMMARY', res.data.resultData);

          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        } else {
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        }
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
      })
      .catch((error) => {
        console.log(error);
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      });
  },

  // 필터 박스플롯 차트 데이터 조회
  FETCH_BOXPLOT_DATA({ commit }, selectedFilterData) {
    // 오버레이 보이기
    commit('SET_SHOW_OVERLAY', true);
    filterAxiosCall
      .fetchFilterBoxplotData(JSON.parse(JSON.stringify(selectedFilterData)))
      .then((res) => {
        if (res.data.resultFlag) {
          // 정상적으로 조회가 된 경우
          commit('SET_BOXPLOT_DATA', res.data.resultData.boxplot);
        } else {
          // 차트 데이터 조회시 오류가 난 경우
          commit('SET_RESULT', { resultFlag: false, resultMsg: res.data.resultMsg });
        }
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
      })
      .catch((error) => {
        console.log(error);
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      });
  },

  // 필터로 조회한 건물 목록 엑셀 다운로드
  async DOWNLOAD_EXCEL({ commit }, selectedfilterData) {
    // 오버레이 보이기
    commit('SET_SHOW_OVERLAY', true);
    await filterAxiosCall
      .downloadExcel(selectedfilterData)
      .then((res) => {
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);

        if (res.data.size === 0) {
          commit('SET_RESULT', { resultFlag: false, resultMsg: '다운받을 건물 정보가 없습니다.' });
        } else {
          utils.fnExcelDown(res);
          commit('SET_RESULT', { resultFlag: true, resultMsg: '정상적으로 처리되었습니다.' });
        }
      })
      .catch((error) => {
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        console.log(error);
      });
  },

  // 선택된 필터 정보 가져오기
  FETCH_FILTER_INFO({ commit }) {
    filterAxiosCall
      .fetchFilterInfoData()
      .then((res) => {
        if (res.data.resultFlag) {
          commit('SET_FILTER_INFO', res.data.resultData);
        }
      })
      .catch((error) => {
        // 오버레이 숨기기
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        console.log(error);
      });
  },
  async FETCH_FILTER_INFO_NEW({ commit }, payload) {
    await filterAxiosCall
      .fetchFilterInfoDataNew(payload)
      .then((res) => {
        if (res.data.resultFlag) {
          commit('SET_FILTER_INFO', res.data.resultData);
        }
      })
      .catch((error) => {
        // 오버레이 숨기기
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        console.log(error);
      });
  },

  // 데이터 조회
  async FETCH_FILTER_DATA({ commit }, filterSelected) {
    // 오버레이 보이기
    commit('SET_SHOW_OVERLAY', true);

    // 선택된 필터 체크 초기화
    commit('SET_RESET_FILTER_FETCHED_INFO');
    await filterAxiosCall
      .fetchFilterData(filterSelected)
      .then((res) => {
        if (res.data.resultFlag) {
          const resultObj = res.data.resultData;
          Object.keys(res.data.resultData).forEach((element) => {
            // default값 설정
            commit('SET_INIT_FILTER_FETCHED_INFO', {
              key: element,
              value: resultObj[element],
            });
          });
          commit('SET_FILTER_FETCHED_INFO_ADD_CONDITION');
          // 필터 데이터 초기화
          commit('SET_BOXPLOT_DATA', {});
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        } else {
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        }
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
      })
      .catch((error) => {
        console.log(error);
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      });
  },

  async SAVE_FILTER_INFO({ commit, dispatch }, selectedFilterInfo) {
    // 오버레이 보이기
    commit('SET_SHOW_OVERLAY', true);
    await filterAxiosCall
      .saveFilterInfoData(selectedFilterInfo)
      .then((res) => {
        // 저장 결과 메시지 출력
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });

        const { option } = selectedFilterInfo;
        dispatch('FETCH_FILTER_INFO_NEW', { option });
      })
      .catch((error) => {
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        console.log(error);
      });
  },

  async SAVE_FILTER_RENEW_INFO({ commit }, selectedFilterInfo) {
    await filterAxiosCall
      .saveFilterRenewInfoData(selectedFilterInfo)
      .then((res) => {
        // 저장 결과 메시지 출력
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });

        // dispatch("FETCH_FILTER_INFO");
      })
      .catch((error) => {
        // 오버레이 숨기기
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        console.log(error);
      });
  },

  async DELETE_FILTER_INFO({ commit, dispatch }, selectedFilterInfo) {
    // 오버레이 보이기
    commit('SET_SHOW_OVERLAY', true);
    await filterAxiosCall
      .deleteFilterInfoData(selectedFilterInfo)
      .then((res) => {
        // 저장 결과 메시지 출력
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });

        const { option } = selectedFilterInfo;
        dispatch('FETCH_FILTER_INFO_NEW', { option });
      })
      .catch((error) => {
        // 오버레이 숨기기
        commit('SET_SHOW_OVERLAY', false);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        console.log(error);
      });
  },
  async FETCH_FILTER_LOAD_DATA({ commit }, selectedFilterInfo) {
    await filterAxiosCall
      .fetchFilterLoadData(selectedFilterInfo)
      .then((res) => {
        if (res.data.resultFlag) {
          const resultObj = res.data.resultData;
          commit('SET_RESET_FILTER_FETCHED_INFO');

          Object.keys(res.data.resultData).forEach((element) => {
            if (element === 'rgnGbCd') {
              // 라디오 버튼은 배열이 안댐
              commit('SET_INIT_FILTER_FETCHED_INFO', {
                key: element,
                value: resultObj[element][0],
              });
            } else {
              commit('SET_INIT_FILTER_FETCHED_INFO', {
                key: element,
                value: resultObj[element],
              });
            }
          });

          // 필터 데이터 초기화
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        } else {
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        }
      })
      .catch((error) => {
        console.log(error);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      });
  },
  // 조회시 업종명 재 로드
  async FETCH_OPNSVC_LOAD_LIST({ commit }, selectedFilterInfo) {
    await filterAxiosCall.fetchOpnsvcLoadList(selectedFilterInfo).then((res) => {
      if (res.data.resultFlag) {
        // commit("SET_OPNSVC_LOAD_DATA", res.data.resultData);
        const resultObj = res.data.resultData;

        Object.keys(res.data.resultData).forEach((element) => {
          commit('SET_INIT_FILTER_FETCHED_INFO', {
            key: 'openSvcJson',
            value: resultObj[element],
          });
        });

        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
      } else {
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
      }
    });
  },
  async FETCH_FILTER_READ_DATA({ commit }, selectedFilterInfo) {
    await filterAxiosCall
      .fetchFilterLoadData(selectedFilterInfo)
      .then((res) => {
        if (res.data.resultFlag) {
          // 필터 데이터 초기화
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        } else {
          commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });
        }
      })
      .catch((error) => {
        console.log(error);
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
      });
  },

  async SAVE_FILTER_EDIT_OFFER({ commit }, buildInfoEditOffer) {
    await filterAxiosCall
      .saveFilterEditOfferData(buildInfoEditOffer)
      .then((res) => {
        // 저장 결과 메시지 출력
        commit('SET_RESULT', { resultFlag: res.data.resultFlag, resultMsg: res.data.resultMsg });

        // dispatch("FETCH_FILTER_INFO");
      })
      .catch((error) => {
        // 오버레이 숨기기
        commit('SET_RESULT', { resultFlag: false, resultMsg: '처리 중 오류가 발생하였습니다.' });
        console.log(error);
      });
  },
  async FETCH_PER_LIST({ commit }, payload) {
    const { data } = await filterAxiosCall.fetchPerList(payload);
    return data.resultData;
  },
};
