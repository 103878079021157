import Vue from 'vue';
import VueRouter from 'vue-router';
import { cookie } from '@/utils/cookie';

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => window.location.reload());
};

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/building/BuildingService.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true,
      },
    },
    {
      path: '/common-layer',
      name: 'common-layer',
      component: () => import('@/views/commonLayer/IndexSampleView.vue'),
    },
    {
      path: '/building-service',
      name: 'building-service',
      component: () => import('@/views/building/BuildingService.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true,
      },
    },
    {
      path: '/building-service-3d',
      name: 'building-service-3d',
      component: () => import('@/views/building/BuildingService3D.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/filter-service',
      name: 'filter-service',
      component: () => import('@/views/filter/FilterMainRenew.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/filter-service-checkbox',
      name: 'filter-service-checkbox',
      component: () => import('@/views/filter/FilterMain.vue'),
    },
    {
      path: '/auth-login',
      name: 'auth-login',
      component: () => import('@/views/auth/AuthLogin.vue'),
    },
    {
      path: '/auth-join',
      name: 'auth-join',
      component: () => import('@/views/auth/AuthJoin.vue'),
    },
    {
      path: '/auth-find-pw',
      name: 'auth-find-pw',
      component: () => import('@/views/auth/AuthFindPw.vue'),
    },
    {
      path: '/admin-list',
      name: 'admin-list',
      component: () => import('@/views/admin/AdminList.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin-dtl',
      name: 'admin-dtl',
      component: () => import('@/views/admin/AdminDtl.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin-reg',
      name: 'admin-reg',
      component: () => import('@/views/admin/AdminReg.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin-login-hst',
      name: 'admin-login-hst',
      component: () => import('@/views/admin/AdminLoginHst.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin-build-hst',
      name: 'admin-build-hst',
      component: () => import('@/views/admin/AdminBuildHst.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/admin-bookmark-hst',
      name: 'admin-bookmark-hst',
      component: () => import('@/views/admin/AdminBookmarkHst.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/auth-profile',
      name: 'auth-profile',
      component: () => import('@/views/auth/AuthProfile.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/similar-building',
      name: 'similar-building',
      component: () => import('@/views/building/SimilarBuildingCompare.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/openApi-main',
      name: 'openApi-main',
      component: () => import('@/views/openApi/OpenApiMain.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/buildingEnergyReportPdfExport',
      name: 'buildingEnergyReportPdfExport',
      component: () => import('@/views/pdfExport/BuildingEnergyReport.vue'),
      meta: {
        // requiresAuth: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  await Vue.nextTick();

  const accessToken = cookie.getAccessToken();

  const { requiresAuth } = to.meta;
  const isLoginRoute = to.path === '/auth-login'; // 로그인
  const isJoinRoute = to.path === '/auth-join'; // 회원가입

  if (requiresAuth) {
    // 로그인이 필요한 페이지
    if (accessToken) {
      next();
    } else {
      next(isLoginRoute || isJoinRoute ? undefined : '/auth-login');
    }
  } else if ((isLoginRoute || isJoinRoute) && accessToken) {
    // 로그인 또는 가입 페이지로 가는 경우
    next('/');
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
