<template>
  <div>
    <e-charts-comp v-if="chartOption.series && chartOption.series.length > 0" :key="JSON.stringify(chartOption)" :chart-option="chartOption"></e-charts-comp>
    <div v-else>

    </div>
  </div>
</template>

<script>
import EChartsComp from '@/components/charts/EChartsComp.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    EChartsComp,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      resultData: [],
      chartValueName: ['난방', '냉방', '기저', '합계'],
      chartOption: {
        legend: {
          bottom: -5,
          itemWidth: 20,
          itemHeight: 14,
        },
        title: {
          text: '연도별-용도별 백분위 변화',
          left: 'center',
          textStyle: {
            fontSize: 14,
          },
        },
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true },
          },
        ],
        grid: {
          left: '30px',
        },
        series: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      getEnergyChartData: 'main/getEnergyChartData',
      mainSelected: 'main/getSelectedCodeData',
    }),
    climateCheck() {
      return this.mainSelected.climateCheck;
    },
  },
  watch: {
    climateCheck() {
      this.getYearEngyData();
    },
  },
  mounted() {
    this.getYearEngyData();
  },
  methods: {
    async getYearEngyData() {
      try {
        const result = await this.$store.dispatch('main/FETCH_YEAR_ENGY_DATA', {
          mgmBldPk: this.mgmBldPk,
          climateCheck: this.climateCheck,
        });

        if (Array.isArray(result) && result.length > 0) {
          this.resultData = result;

          // xAxis 데이터 설정
          this.chartOption.xAxis[0].data = this.resultData.map((item) => item.useyy);

          const hetgData = [];
          const aconData = [];
          const baseData = [];
          const totalData = [];

          this.resultData.forEach((item) => {
            hetgData.push(item.allhetgqtyperc);
            aconData.push(item.allaconqtyperc);
            baseData.push(item.allbaseqtyperc);
            totalData.push(item.allallqtyperc);
          });

          // chartOption.series 설정
          this.chartOption.series = [
            {
              name: '난방',
              type: 'line',
              data: hetgData,
              color: '#E64A45',
            },
            {
              name: '냉방',
              type: 'line',
              data: aconData,
              color: '#E6772E',
            },
            {
              name: '기저',
              type: 'line',
              data: baseData,
              color: '#F2C249',
            },
            {
              name: '합계',
              type: 'line',
              data: totalData,
              color: '#4DB3B3',
            },
          ];

          // 반응성 보장을 위해 chartOption 객체 다시 할당
          this.chartOption = { ...this.chartOption };
        } else {
          console.error('유효한 데이터가 없습니다:', result);
          this.resultData = [];
        }
      } catch (error) {
        console.error('데이터 가져오기 실패:', error);
        this.resultData = [];
      }
    },
  },
};
</script>
