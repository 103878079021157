<template>
  <b-form-group v-show="options.length > 0" :label="label" label-for="userId">
    <select v-model="selectedOption" class="form-select" aria-label="Default select example" @change="addSelectedOption">
      <option disabled value="">
        선택
      </option>
      <option v-for="(item, index) in filteredOptions" :key="index" :value="item">
        {{ item.engy_esncno.slice(12) }} | {{ item.use_purps_nm }} | {{ item.engy_sply_kik_nm }}
      </option>
    </select>

    <div v-show="selectedOptions.length > 0" class="card">
      <div class="card-body">
        <div v-for="(item, index) in selectedOptions" :key="index">
          {{ item.engy_esncno.slice(12) }} | {{ item.use_purps_nm }} | {{ item.engy_sply_kik_nm }}
          <span @click="removeOption(index)">
            <feather-icon class="mr-15" size="20" color="black" icon="XSquareIcon" />
          </span>
        </div>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import { BBadge, BCol, BForm, BFormGroup, BFormInput, BListGroup, BListGroupItem, BOverlay, BRow } from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code';
import { ValidationObserver } from 'vee-validate';

export default {
  components: {
    BOverlay,
    BBadge,
    BCardCode,
    BFormInput,
    BCol,
    BFormGroup,
    BForm,
    BRow,
    BListGroup,
    BListGroupItem,
    ValidationObserver,
  },

  props: {
    label: String,
    engyKindCd: String,
    options: Array,
    savedMeter: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOption: '',
      selectedOptions: [],
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((item) => item.engy_kind_cd === this.engyKindCd);
    },
  },
  watch: {
    savedMeter: {
      immediate: true,
      handler(newVal) {
        this.selectedOptions = [];
        if (Array.isArray(newVal) && newVal.length > 0 && this.selectedOptions.length === 0) {
          const savedMetersForThisEnergy = newVal.filter((meter) => meter.engy_kind_cd === this.engyKindCd);
          if (savedMetersForThisEnergy.length > 0) {
            this.selectedOptions = savedMetersForThisEnergy;
            this.$emit('update:selectedOptions', this.selectedOptions);
          }
        }
      },
    },
  },
  methods: {
    addSelectedOption() {
      const selectedItem = this.selectedOption;
      if (selectedItem && !this.selectedOptions.includes(selectedItem)) {
        this.selectedOptions.push(selectedItem);
        this.$emit('update:selectedOptions', this.selectedOptions); // 선택된 항목이 변경될 때마다 emit
      }
      this.selectedOption = '';
    },
    removeOption(index) {
      this.selectedOptions.splice(index, 1);
      this.$emit('update:selectedOptions', this.selectedOptions); // 선택된 항목이 변경될 때마다 emit
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 5px;
  border: thin dashed;
}
</style>
