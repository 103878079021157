<template>
  <div>
    <div class="mb-2">
      <h3>
        입주사별 기본정보
        <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
      </h3>
    </div>
    <div v-if="loading" style="text-align: center">
      <b-spinner class="m-5" label="Busy" variant="dark" />
    </div>
    <div v-else>
      <b-table-simple bordered class="rounded-bottom mb-0" hover responsive>
        <colgroup>
          <col style="width: 20%;" />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <b-thead>
          <b-tr>
            <b-th class="text-center align-middle" rowspan="2">
              업종명
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              상호명
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              업태
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              층번호
            </b-th>
            <b-th class="text-center align-middle" rowspan="2">
              면적(m²)
            </b-th>
            <b-th class="text-center align-middle" colspan="4">
              연결 계량기 정보
            </b-th>
          </b-tr>
          <b-tr>
            <b-th class="text-center align-middle">
              전기
            </b-th>
            <b-th class="text-center align-middle">
              도시가스
            </b-th>
            <b-th class="text-center align-middle">
              지역난방
            </b-th>
            <b-th class="text-center align-middle">
              계량기수
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="bldTenant in bldTenants" :key="bldTenant.id" class="text-center">
            <b-td>{{ bldTenant.opnsvcnm }}</b-td>
            <b-td>{{ bldTenant.bplcnm }}</b-td>
            <b-td>{{ bldTenant.uptaenm }}</b-td>
            <b-td>{{ bldTenant.flr_no_nm }}</b-td>
            <b-td>{{ bldTenant.sitearea }}</b-td>
            <b-td>{{ bldTenant.elec_use_purps_nms || '-' }}</b-td>
            <b-td>{{ bldTenant.gas_use_purps_nms || '-' }}</b-td>
            <b-td>{{ bldTenant.hetg_use_purps_nms || '-' }}</b-td>
            <b-td>{{ bldTenant.engy_pk_count || '-' }}</b-td>
          </b-tr>
        </b-tbody>
        <!--      <b-tbody >
        <b-tr>
          <b-td
            class="text-center align-middle"
            colspan="9"
            variant="light"
          >검색된 데이터가 없습니다.
          </b-td>
        </b-tr>
      </b-tbody>-->
      </b-table-simple>
    </div>
  </div>
</template>
<script>
import { BTable, BTableSimple, BTbody, BTd, BTh, BThead, BTr, BButton, BSpinner } from 'bootstrap-vue';

export default {
  components: {
    BSpinner,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTableSimple,
    BTd,
    BTable,
    BButton,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bldTenants: [],
      loading: false,
    };
  },
  async mounted() {
    this.bldTenants = await this.$store.dispatch('main/FETCH_BUILD_TENANT_INFO_DATA', {
      mgmBldPk: this.mgmBldPk,
    });
  },
  methods: {},
};
</script>

<style scoped>
table thead th {
  text-transform: none;
}
</style>
