<template>
  <div>
    <app-echart-bar :option-data="chartOption" />
  </div>
</template>
<script>
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue';

export default {
  components: {
    AppEchartBar,
  },
  props: {
    chartOption: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      option: {},
    };
  },
};
</script>
<style scoped></style>
