<template>
  <div>
    <div class="mb-2">
      <h3>
        입주사별 에너지 소비량
        <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
      </h3>
    </div>
    <div>
      <b-row class="d-flex justify-content-end">
        <b-col cols="9">
          <h4>에너지원별 에너지 사용량</h4>
        </b-col>
        <b-col cols="3">
          <b-form-group label-for="useYyCd">
            <v-select id="useYyCd" v-model="mainSelected.useYyCd" label="cdNm" taggable push-tags :options="commCodeData.USE_YY_CD" @input="getBldTenantInfo($event)" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row>
        <b-col cols="9">
          <b-table-simple bordered class="rounded-bottom mb-0" hover responsive>
            <colgroup>
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col style="width: 25%" />
              <col />
            </colgroup>
            <b-thead>
              <b-tr>
                <b-th class="text-center align-middle">
                  전기(kWh/m²)
                </b-th>
                <b-th class="text-center align-middle">
                  가스(kWh/m²)
                </b-th>
                <b-th class="text-center align-middle">
                  열(kWh/m²)
                </b-th>
                <b-th class="text-center align-middle">
                  합계(kWh/m²)
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="bldTenantEngyUse && bldTenantEngyUse.length > 0">
              <b-tr class="text-center">
                <b-td>{{ getBldTenantEngyUseValue('elecallqty') }}</b-td>
                <b-td>{{ getBldTenantEngyUseValue('gasallqty') }}</b-td>
                <b-td>{{ getBldTenantEngyUseValue('hetgallqty') }}</b-td>
                <b-td>{{ getBldTenantEngyUseValue('allallqty') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr class="text-center">
                <b-td colspan="4">
                  에너지 사용량 데이터가 없습니다.
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-row class="mt-2">
            <b-col cols="4">
              <tenant-year-engy-chart :chart-data="chartData"></tenant-year-engy-chart>
              <!---->
              <!--              <echart-bar :mgm-bld-pk="mgmBldPk" :energy-chart-type="engyKind" :use-yy-selected="useYySelected.cdId" />-->
            </b-col>
            <b-col cols="4">
              <tenant-year-engy-type-use-chart :chart-data="chartTypeUse"></tenant-year-engy-type-use-chart>
            </b-col>
            <b-col cols="4">
              <tenant-year-engy-type-use-chart2 :chart-data="chartTypeUse2"></tenant-year-engy-type-use-chart2>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="3">
          <b-list-group>
            <b-list-group-item v-for="(bldTenant, index) in bldTenants" :key="bldTenant.id" button :active="activeItem === index" @click="setActive(index, bldTenant.id)">
              {{ bldTenant.bplcnm }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { BRow, BCol, BThead, BTbody, BTh, BTr, BTd, BTableSimple, BListGroup, BListGroupItem, BFormGroup } from 'bootstrap-vue';
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue';
import vSelect from 'vue-select';
import { mapGetters } from 'vuex';
import EChartsComp from '@/components/charts/EChartsComp.vue';
import TenantYearEngyChart from '~/building/tenantInfo/TenantYearEngyChart.vue';
import TenantYearEngyTypeUseChart from '~/building/tenantInfo/TenantYearEngyTypeUseChart.vue';
import TenantYearEngyTypeUseChart2 from '~/building/tenantInfo/TenantYearEngyTypeUseChart2.vue';
import EchartBar from '~/building/EchartEnergy/EchartBar.vue';

export default {
  components: {
    EchartBar,
    EChartsComp,
    TenantYearEngyChart,
    TenantYearEngyTypeUseChart,
    TenantYearEngyTypeUseChart2,
    BFormGroup,
    AppEchartBar,
    BTableSimple,
    BTd,
    BTr,
    BTh,
    BTbody,
    BThead,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    vSelect,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bldTenants: [],
      activeItem: null,
      chartData: [],
      chartTypeUse: [],
      chartTypeUse2: [],

      bldTenantEngyUse: [], // 입주사 에너지 사용량
    };
  },
  computed: {
    ...mapGetters({
      commCodeData: 'main/getCommCodeData',
      mainSelected: 'main/getSelectedCodeData',
    }),
  },
  async mounted() {
    await this.getBldTenantInfo();
  },
  methods: {
    async getBldTenantInfo() {
      const data = await this.$store.dispatch('main/FETCH_BUILD_TENANT_INFO_DATA', {
        mgmBldPk: this.mgmBldPk,
        isData: true,
      });

      if (data !== null) {
        this.bldTenants = data;
        await this.setActive(0, this.mainSelected.mgmBldId);
        await this.getChartData();
        await this.getChartTypeUseData();
        await this.getChartTypeUseData2();
      }
    },
    async setActive(index, id) {
      this.activeItem = index;
      this.mgmBldId = id;

      try {
        const data = await this.$store.dispatch('main/FETCH_BUILD_TENANT_ENGY_USE_DATA', {
          mgmBldPk: this.mgmBldPk,
          mgmBldId: id,
          useYyCd: this.mainSelected.useYyCd.cdId,
          // mgmBldId: 881078,
          // useYyCd: '2021',
        });

        this.bldTenantEngyUse = Array.isArray(data) ? data : data ? [data] : [];

        await this.getChartData();
        await this.getChartTypeUseData();
        await this.getChartTypeUseData2();
      } catch (error) {
        console.error('에너지 사용량 데이터를 가져오는 중 오류가 발생했습니다:', error);
        this.bldTenantEngyUse = [];
      }
    },
    getBldTenantEngyUseValue(key) {
      if (this.bldTenantEngyUse && this.bldTenantEngyUse.length > 0 && this.bldTenantEngyUse[0]) {
        return this.bldTenantEngyUse[0][key] || '-';
      }
      return '-';
    },
    async getChartData() {
      const chartData = await this.$store.dispatch('main/FETCH_TENANT_YEAR_ENGY_DATA', {
        mgmBldPk: this.mgmBldPk,
        mgmBldId: this.mainSelected.mgmBldId,
        useYyCd: this.mainSelected.useYyCd.cdId,
        // mgmBldId: 881078,
        // useYyCd: '2021',
      });
      if (chartData && chartData.length > 0) {
        this.chartData = chartData;
      }
    },
    async getChartTypeUseData() {
      const chartTypeUse = await this.$store.dispatch('main/FETCH_TENANT_YEAR_ENGY_TYPE_USE_DATA', {
        mgmBldPk: this.mgmBldPk,
        mgmBldId: this.mainSelected.mgmBldId,
        useYyCd: this.mainSelected.useYyCd.cdId,
        // mgmBldId: 881078,
        // useYyCd: '2021',
      });
      if (chartTypeUse && chartTypeUse.length > 0) {
        this.chartTypeUse = chartTypeUse;
      }
    },
    async getChartTypeUseData2() {
      const chartTypeUse2 = await this.$store.dispatch('main/FETCH_TENANT_YEAR_ENGY_TYPE_USE2_DATA', {
        mgmBldPk: this.mgmBldPk,
        mgmBldId: this.mainSelected.mgmBldId,
        useYyCd: this.mainSelected.useYyCd.cdId,
        // mgmBldId: 881078,
        // useYyCd: '2021',
      });
      if (chartTypeUse2 && chartTypeUse2.length > 0) {
        this.chartTypeUse2 = chartTypeUse2;
      }
    },
  },
};
</script>
<style scoped>
.text-center {
  height: 40px;
  text-transform: none;
}
</style>
