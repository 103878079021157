<template>
  <div>
    <b-row>
      <b-col md="6" sm="8" />
      <b-col md="6" class="mb-1">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="검색" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              초기화
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <b-table
          responsive
          :current-page="currentPage"
          :fields="header"
          :items="rows"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          table-class="edocmy-table"
          thead-class="edocmy-thead"
          tbody-class="edocmy-tbody" 
          @filtered="onFiltered"
        >
        <!-- bldNm이 존재하지 않을 때 '-'로 표기 -->
         <template v-slot:cell(bldNm)="row">
           {{ row.item.bldNm || '-' }}
         </template>

         <!-- dongNm이 존재하지 않을 때 '-'로 표기 -->
         <template v-slot:cell(dongNm)="row">
           {{ row.item.dongNm || '-' }}
         </template>
          <template v-slot:cell(createdDt)="row">
            <div class="d-flex justify-content-between">
              <div class="d-inline-block">
                <b-badge
                  size="sm"
                  pill
                   :variant="row.item.engyBldYn === 'Y' ? 'warning' : 'secondary'"
                  class="bldBadge"
                >
                  에너지정보
                </b-badge>
                <b-badge
                  size="sm"
                  pill
                  :variant="row.item.bepaYn === 'Y' ? 'success' : 'secondary'"
                  class="bldBadge"
                >
                  BEPA리포트
                </b-badge>
                <b-badge
                  size="sm"
                  pill
                  :variant="row.item.kbossYn  === 'Y' ? 'danger' : 'secondary'"
                  class="bldBadge"
                >
                  K-BOSS
                </b-badge>
              </div>
              <div class="d-inline-block ml-auto">
                <feather-icon size="26" icon="ZoomInIcon" class="cursor-pointer" @click="goDtl(row.item)" />
              </div>
            </div>
          </template>
        </b-table>
        <div class="tbFooter">
          <div>
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="rows.length"
              aria-controls="my-table"
            />
          </div>
        </div>
        <div v-show="loading" class="text-center pt-1">
          <b-spinner class="m-5" label="Busy" variant="dark" />
        </div>
        <div>
          <b-modal
            v-model="modalBuildTotal"
            centered
            ok-only
            ok-title="닫기"
            size="xl"
            :title="`건물 에너지 광역검진정보 (소재지: ${buildTotalInfo.bldAddr} / ${buildTotalInfo.bldNm}) `"
          >
            <build-total-main :mgm-bld-pk="buildTotalInfo.mgmBldPk" />
          </b-modal>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BButton,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BBadge,
} from 'bootstrap-vue';
import BuildTotalMain from '~/building/buildTotalInfo/buildTotalMain.vue';

export default {
  components: {
    BuildTotalMain,
    BModal,
    BSpinner,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BBadge,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      selected: [],
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      header: [
        { label: '건축물명', key: 'bldNm', class: 'bldNm-class'},
        { label: '건물명칭', key: 'dongNm', class: 'dongNm-class'},
        { label: '소재지', key: 'platAddr' , class: 'platAddr-class'},
        { label: '건물정보 ', key: 'createdDt' , class: 'createdDt-class'},
      ],
      rows: [],
      // 선택한 건물 PK
      selectMgmBlkPk: '',
      modalBuildTotal: false, // 건물 정보 토탈
      buildTotalInfo: {},
    };
  },
  async mounted() {
    this.rows = await this.$store.dispatch('auth/FETCH_AUTH_BOOKMARK', {
      userId: this.userId,
    });
    this.loading = false;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async goDtl(bldInfoData) {
      this.$store.state.main.selectMenuComp = null;

      // modal 타이틀에 건물정보, 주소를 입력하기 위해 db 조회
      const res = await this.$store.dispatch('main/FETCH_BUILD_TOTAL_TITLE_DATA', {
        mgmBldPk: bldInfoData.mgmBldPk,
      });

      this.modalBuildTotal = !this.modalBuildTotal;
      this.buildTotalInfo.bldNm = res.bldnm;
      this.buildTotalInfo.bldAddr = res.roadplataddr;
      this.buildTotalInfo.mgmBldPk = res.mgmbldpk;

      // 해당 건물로 이동
      gis.search.moveToBldCrd(map, {
        x: bldInfoData.stX,
        y: bldInfoData.stY,
        mgmBldPk: bldInfoData.mgmBldPk,
      });
    },
  },
};
</script>
<style lang="scss">
.tbFooter {
  text-align: center;
  padding: 30px 0;
}

.tbFooter div {
  display: inline-block;
}

.regBtnArea {
  float: right;
}
.d-flex.justify-content-between .d-inline-block { 
    padding-left:10px;
    padding-top:5px;
    padding-bottom:0px;
}
.d-inline-block.ml-auto {
    padding : 4px;
}
.d-inline-block.ml-auto .cursor-pointer{
    padding-bottom: 2px;
 }
.d-inline-block .bldBadge{ 
    margin-right: 3px;
}
.edocmy-thead th[class$="-class"]{
    background-color: #f3f2f7 !important;
    padding: 3px 0px 3px 0px;
    text-align: center;
    font-size : 100%;
    font-weight: bold;
}
.edocmy-tbody td.bldNm-class{
    text-align: left;
    width : 270px;
}
.edocmy-tbody td.dongNm-class{
    text-align: center;
    width : 100px;
}
.edocmy-tbody td.platAddr-class{
    text-align: left;
}
.edocmy-tbody td.createdDt-class{
    text-align: center;
    width : 270px;
}

.edocmy-tbody td[class$="-class"]{
    padding: 2px 4px;
    border: 1px solid #cccccc;
    border-radius: 0px;
    min-width: 100px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}
</style>
