import { mainAxiosCall } from '@api/main';
import { utils } from '@/utils/commUtils';
import axios from 'axios';

export default {
  /** *********** 공통 함수 영역  ***********  */
  FETCH_COMM_CODE_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      mainAxiosCall
        .fetchCommCodeData()
        .then((res) => {
          if (res.data.resultFlag) {
            commit('SET_COMM_CODE_DATA', res.data.resultData);

            const defaultKeyInfoList = utils.fnFindDefaultValue4Map(res.data.resultData, 'defValYn');
            if (defaultKeyInfoList !== undefined && defaultKeyInfoList !== null) {
              Object.keys(defaultKeyInfoList).forEach((element) => {
                // default값 설정
                commit('SET_INIT_SELECTED_COMM_CODE', {
                  key: element,
                  value: defaultKeyInfoList[element],
                });
              });
            }
            resolve();
          } else {
            alert(res.data.resultMsg);
            reject(new Error(res.data.resultMsg));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 시도 데이터 조회
  FETCH_SIDO_LIST({ commit, dispatch, state }) {
    return new Promise((resolve, reject) => {
      mainAxiosCall
        .fetchSidoList()
        .then((res) => {
          if (res.data.resultFlag) {
            commit('SET_SIDO_LIST', res.data.resultData);

            // default값 설정
            const defaultSido = utils.fnFindDefaultValue4Array(res.data.resultData, 'defValYn');

            if (defaultSido !== undefined && defaultSido !== null) {
              // defaultValue가 설정되어 있는 경우
              commit('SET_INIT_SELECTED_COMM_CODE', {
                key: 'SIDO',
                value: defaultSido,
              });
              // 시군구 데이터 조회
              const { sidoId } = state.selected.SIDO[0];
              dispatch('FETCH_SIGUNGU_LIST', sidoId);
            }
            resolve();
          } else {
            alert(res.data.resultMsg);
            reject(new Error(res.data.resultMsg));
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  // 시군구 데이터 조회
  FETCH_SIGUNGU_LIST({ commit }, sidoId) {
    // 선택된 시군구 정보 초기화
    commit('SET_INIT_SELECTED_ADDR', { key: 'SIGUNGU', value: [] });

    if (sidoId !== null) {
      mainAxiosCall
        .fetchSigunguList(sidoId)
        .then((res) => {
          if (res.data.resultFlag) {
            // 시군구 데이터 설정
            commit('SET_SIGUNGU_LIST', res.data.resultData);
          } else {
            alert(res.data.resultMsg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      commit('SET_SIGUNGU_LIST', []);
    }
  },

  /** *********** 지도 함수 영역  ***********  */
  // 인허가 업종 검색 ex)스타벅스
  async FETCH_SEARCH_AUTH_DATA({ commit }, payload) {
    await mainAxiosCall.fetchSearchAuthData(JSON.parse(JSON.stringify(payload))).then((res) => {
      console.log('res.dataAuthSearch > ', res.data);
      if (res.data.resultFlag) {
        commit('SET_SEARCH_AUTH_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 건물 기본 정보
  async FETCH_BUILD_BASIC_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildBasicData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BUILD_BASIC_DATA', res.data.resultData);
        commit('SET_LAYER_INFO', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 건물 층별 개요
  async FETCH_BUILD_FLOOR_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildFloorData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BUILD_FLOOR_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 지도 영역 데이터
  async FETCH_MAP_AREA_DATA({ commit }, payload) {
    await mainAxiosCall.fetchMapAreaData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_MAP_AREA_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 인허가 데이터 정보
  async FETCH_BUILD_LICENSE_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildLicenseData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BUILD_LICENSE_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 에너지 사용량
  async FETCH_ENERGY_USE_DATA({ commit }, payload) {
    commit('SET_ENERGY_USE_YEAR_DATA', payload);
    const { data } = await mainAxiosCall.fetchEnergyUseData(payload);
    return data.resultData;
  },
  // 에너지 종류별 에너지 사용량
  async FETCH_ENERGY_KIND_CHART_DATA({ commit }, payload) {
    await mainAxiosCall.fetchEnergyChartData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_ENERGY_KIND_CHART_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 용도별 에너지 사용량
  async FETCH_CLSF_KIND_CHART_DATA({ commit }, payload) {
    await mainAxiosCall.fetchEnergyChartData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_CLSF_KIND_CHART_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 계량기 정보
  async FETCH_ENERGY_METER_INFO_DATA({ commit }, payload) {
    await mainAxiosCall.fetchEnergyMeterInfoData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_ENERGY_METER_INFO_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 올가미 선택한 용도별 통계 요약
  async FETCH_BUILD_LASSO_STMT_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildLassoStmtData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BUILD_LASSO_STMT_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  // 올가미 건물 선택 리스트
  async FETCH_BUILD_LASSO_CHOICE_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildLassoChoiceData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BUILD_LASSO_CHOICE_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  //   시군구, 읍면동는 주용도검색 비활성
  //    건물일 경우 주용도검색 활성
  FETCH_ENERGY_USE_CHANGE({ commit }, engyUseChange) {
    commit('SET_ENERGY_USE_CHANGE', engyUseChange);
  },
  // 주용도검색 선택값 state 저장
  FETCH_SELECT_PURPS_DATA({ commit }, mainPurpsCd) {
    commit('SET_SELECT_PURPS_DATA', mainPurpsCd);
  },
  // 임시 저장된 데이터로 건물정보 로드
  async FETCH_TEMP_BUILD_INFO_DATA({ commit }, payload) {
    await mainAxiosCall.fetchTempBuildInfoData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_TEMP_BUILD_INFO_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  async FETCH_NEW_ENERGY_HEAT_DATA({ commit }, mgmBldPk) {
    await mainAxiosCall.fetchNewEnergyHeatData(mgmBldPk).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_NEW_ENERGY_HEAT_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  async FETCH_TOTAREA_DSTRB_DATA({ commit }, payload) {
    await mainAxiosCall.fetchTotareaDstrbData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_TOTAREA_DSTRB_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  FETCH_BUILD_EDIT_OFFER_RESET({ commit }) {
    commit('SET_BUILD_EDIT_OFFER_RESET');
  },
  async FETCH_BUILD_MAPNG_GRADE_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildMapngGradeData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BUILD_MAPNG_GRADE_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },

  async FETCH_BUILD_READ_HISTORY_DATA({ commit }, payload) {
    await mainAxiosCall.fetchBuildReadHistoryData(payload).then((res) => {
      if (res.data.resultFlag) {
        commit('SET_BUILD_READ_HISTORY_DATA', res.data.resultData);
      } else {
        alert(res.data.resultMsg);
      }
    });
  },
  async FETCH_PDF_BEPA_EXIST({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchPdfBepaExist(payload);
    return data.resultFlag;
  },
  async FETCH_BUILD_GET_BOOKMARK_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildGetBookmarkData(payload);
    return data.resultFlag;
  },
  async FETCH_BUILD_SET_BOOKMARK_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildSetBookmarkData(payload);
    return data.resultFlag;
  },
  async FETCH_BUILD_USE_YY_CD({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildUseYyCd(payload);

    if (data.resultFlag) {
      commit('SET_BUILD_USE_YY_DATA', data.resultData);
    }
    return data.resultData;
  },
  async FETCH_BUILD_TOTAL_TITLE_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildTotalTitleData(payload);
    return data.resultData;
  },
  async FETCH_SELECT_MENU_COMP({ commit }, payload) {
    commit('SET_SELECT_MENU_COMP', payload);
  },
  async FETCH_SELECTED_SIMILAR_BUILDING_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchSelectedSimilarBuildingData(payload);

    return data;
  },
  async FETCH_SIMILAR_BUILDING_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchSimilarBuildingData(payload);
    return data.resultData;
  },
  async FETCH_BUILD_SEARCH_BLD_DATA({ commit }, payload) {
    commit('SET_BUILD_SEARCH_BLD_DATA', payload);
  },
  async FETCH_BUILD_KBOSS_MAP_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildKbossMapData(payload);

    return data.resultData;
  },
  async FETCH_BUILD_KBOSS_DELETE({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildKbossDelete(payload);
    return data.resultFlag;
  },
  async FETCH_BUILD_GET_KBOSS_YN({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildGetKbossYn(payload);
    return data.resultFlag;
  },
  async FETCH_BUILD_GRADE_LIST({ commit }) {
    const { data } = await mainAxiosCall.fetchBuildGradeList();
    return data.resultData;
  },
  async FETCH_FLOOR_NO({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchFloorNo(payload);
    return data.resultData;
  },
  async FETCH_YEAR_ENGY_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchYearEngyData(payload);
    return data.resultData;
  },
  async FETCH_YEAR_TABLE_ENGY_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchYearTableEngyData(payload);
    return data.resultData;
  },
  async FETCH_BUILD_BASIC_ONE_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildBasicOneData(payload);
    return data.resultData;
  },
  async FETCH_BUILD_BASIC_ONE_LIST_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildBasicOneListData(payload);
    return data.resultData;
  },
  async FETCH_BUILD_BASIC_TOTAL_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildBasicTotalData(payload);
    if (data.resultFlag) {
      commit('SET_BUILD_TOTAL_DATA', 'Y'); // 총괄 표제부 건물이 있을 경우 Y
    } else {
      commit('SET_BUILD_TOTAL_DATA', 'N');
    }
    return data.resultData;
  },
  async FETCH_ADDRESS_SEARCH_DATA({ commit }, payload) {
    try {
      const response = await axios.get('https://business.juso.go.kr/addrlink/addrLinkApi.do', {
        params: {
          currentPage: payload.currentPage,
          countPerPage: payload.countPerPage,
          keyword: payload.keyword,
          confmKey: payload.confmKey,
          resultType: 'json',
        },
      });

      const result = response.data.results;

      if (result.common.errorCode === '0') {
        // 정상적으로 데이터를 받았을 경우
        commit('SET_ADDRESS_DATA', result);
        return result;
      }
      console.error('API 에러:', result.common.errorMessage);
    } catch (error) {
      console.error('API 요청 실패:', error);
    }
  },
  async FETCH_SEARCH_COORD_DATA({ commit }, payload) {
    try {
      const response = await axios.get('https://business.juso.go.kr/addrlink/addrCoordApi.do', {
        params: {
          admCd: payload.admCd, // 행정동 코드
          rnMgtSn: payload.rnMgtSn, // 도로명 관리번호
          buldMnnm: payload.buldMnnm, // 건물 본번
          buldSlno: payload.buldSlno, // 건물 부번
          udrtYn: payload.udrtYn, // 지하 여부 (0: 지상, 1: 지하)
          confmKey: 'U01TX0FVVEgyMDIzMDYxNjExMDkxMzExMzg1NTk=', // 인증키
          resultType: 'json',
        },
      });

      const result = response.data.results;

      if (result.common.errorCode === '0') {
        // 정상적으로 데이터를 받았을 경우
        commit('SET_ADDRESS_DATA', result);
        return result;
      }
      console.error('API 에러:', result.common.errorMessage);
    } catch (error) {
      console.error('API 요청 실패:', error);
    }
  },
  async FETCH_BUILD_TENANT_INFO_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildTenantInfoData(payload);
    if (data.resultFlag) {
      commit('SET_BUILD_MGM_BLD_ID', data.resultData[0].id);
    }

    return data.resultData;
  },
  async FETCH_BUILD_TENANT_ENGY_ID_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildTenantEngyData(payload);
    return data.resultData;
  },
  async FETCH_BUILD_FLR_INFO_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildFlrInfoData(payload);
    return data.resultData;
  },
  async FETCH_BUILD_TENANT_INSERT_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildTenantInsertData(payload);
    return data.resultFlag;
  },
  async FETCH_BUILD_SAVED_METER_INFO_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildSavedMeterInfoData(payload);
    return data.resultData;
  },
  async FETCH_MGM_UPPER_BLD_PK({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchMgmUpperBldPk(payload);
    return data.resultData;
  },
  async FETCH_BUILD_TENANT_ENGY_USE_DATA({ commit }, payload) {
    commit('SET_BUILD_MGM_BLD_ID', payload.mgmBldId);
    const { data } = await mainAxiosCall.fetchBuildTenantEngyUseData(payload);
    return data.resultData;
  },
  async FETCH_TENANT_YEAR_ENGY_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchTenantYearEngyData(payload);
    return data.resultData;
  },
  async FETCH_BUILD_TENANT_COMPARE_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchBuildTenantCompareData(payload);
    return data.resultData;
  },
  async FETCH_TENANT_YEAR_ENGY_TYPE_USE_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchTenantYearEngyTypeUseData(payload);
    return data.resultData;
  },
  async FETCH_TENANT_YEAR_ENGY_TYPE_USE2_DATA({ commit }, payload) {
    const { data } = await mainAxiosCall.fetchTenantYearEngyTypeUse2Data(payload);
    return data.resultData;
  },
};
