<template>
  <div>
    <div class="mb-2">
      <h3>인허가 리스트
      <span class="caution">해당 정보는 법적 효력이 없으며, 참고 자료로만 활용 가능합니다</span>
     </h3>
    </div>
    <div>
      <b-table striped responsive :items="items" :fields="fields" class="row-modal" 
      table-class="edocll-table"
      thead-class="edocll-thead"
      tbody-class="edocll-tbody"
      />
      <div v-show="isData" class="noData">
        <p>데이터가 존재하지 않습니다.</p>
      </div>
      <div v-show="loading" class="text-center">
        <b-spinner class="m-5" label="Busy" variant="dark" />
      </div>
    </div>
  </div>
</template>

<script>
import { BTable, BSpinner } from 'bootstrap-vue';

export default {
  components: {
    BTable,
    BSpinner,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      isData: false,
      fields: [
        {
          key: 'opnsvcnm',
          label: '업종명',
          class: 'opnsvcnm-class',
        },
        {
          key: 'bplcnm',
          label: '상호명',
          class: 'bplcnm-class',
        },
        {
          key: 'sitewhladdr',
          label: '인허가 주소',
          class: 'sitewhladdr-class',
        },
        {
          key: 'uptaenm',
          label: '업태',
          class: 'uptaenm-class',
        },
      ],
      items: [],
    };
  },
  created() {
    // 인허가 정보 데이터
    this.$store
      .dispatch('main/FETCH_BUILD_LICENSE_DATA', {
        dataCode: 'buildLicenseData',
        mgmBldPk: this.mgmBldPk,
      })
      .then(() => {
        this.loading = false;
        this.items = this.$store.state.main.licenseList;
        if (this.items.length === 0) this.isData = true;
      })
      .catch(() => {
        console.error('오류');
      });
  },
};
</script>
<style lang="scss">
.row-modal {
  font-family: Helvetica Neue, Arial, sans-serif;
}
.noData {
  text-align: center;
  border: 1px solid #cccccc;
  border-top-width: 0;
  display: block;
  top: -15px;
  position: relative;
  padding-top: 15px;
}

.edocll-thead th[class$="-class"]{
    background-color: #f3f2f7 !important;
    padding: 3px 0px 3px 0px;
    text-align: center;
    font-size : 100%;
    font-weight: bold;
}
.edocll-tbody td.opnsvcnm-class{
    text-align: center;
    width:20%;
}
.edocll-tbody td.bplcnm-class{
    text-align: left;
    width:30%;
}
.edocll-tbody td.sitewhladdr-class{
    text-align: left;
    width:35%;
}
.edocll-tbody td.uptaenm-class{
    text-align: center;
    width:15%;
}

.edocll-tbody td[class$="-class"]{
    padding: 2px 2px;
    border: 1px solid #cccccc;
    border-radius: 0px;
    min-width: 100px;
    vertical-align: middle;   /* 수직 중앙 정렬 */
    white-space: nowrap;      /* 줄 바꿈 방지 */
    overflow: hidden;         /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis;  /* 넘치는 텍스트 말줄임 표시 */
}
</style>
