<template>
  <div class="naviArea">
    <b-navbar>
      <!-- 로고 이미지 -->
      <b-navbar-brand href="/">
        <img src="/mainLogo1.png" alt="logo" class="brand-logo" />
      </b-navbar-brand>

      <!-- 가운데 정렬된 메뉴 -->
      <b-navbar-nav class="mx-auto">
        <!-- 메뉴 항목 1 -->
        <!--        <div class="menu-item d-flex align-items-center ">
          <b-nav-item-dropdown
            text="광역검진지도"
            class="custom-dropdown font-weight-bolder h4 nav-title-style"
          >
            <b-dropdown-item @click="goto2DMap()">
              <feather-icon class="mr-50" icon="MapIcon" />2D 지도 보기
            </b-dropdown-item>
            <b-dropdown-item @click="goto3DMap()">
              <feather-icon class="mr-50" icon="GlobeIcon" />3D 지도 보기
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </div>-->

        <!--        <b-nav-item class="menu-item font-weight-bolder h4">
                  <li class="fas fa-map-marker-alt"></li>
                  <span class="nav-title-style">광역검진지도</span>
                  <b-button
                    :variant="current === 'building-service' ? 'primary' : 'outline-primary'"
                    :class="current === 'building-service' ? '' : 'bg-light'"
                    class="ml-1"
                    size="sm"
                    style="border-top-right-radius:0;border-bottom-right-radius:0"
                    @click="goto2DMap()"
                  >
                    2D
                  </b-button>
                  <b-button
                    :variant="current === 'building-service-3d' ? 'primary' : 'outline-primary'"
                    :class="current === 'building-service-3d' ? '' : 'bg-light'"
                    size="sm"
                    style="border-top-left-radius:0;border-bottom-left-radius:0"
                    @click="goto3DMap()"
                  >
                    3D
                  </b-button>
                </b-nav-item>-->
        <!-- 메뉴 항목 1 -->
        <b-nav-item href="/building-service" class="menu-item font-weight-bolder h4">
          <li class="fas fa-map-marker-alt"></li>
          <span class="nav-title-style">광역검진지도</span>
        </b-nav-item>
        <!-- 메뉴 항목 2 -->
        <b-nav-item href="/filter-service" class="menu-item font-weight-bolder h4">
          <li class="fas fa-filter"></li>
          <span class="nav-title-style">검진필터 서비스</span>
        </b-nav-item>

        <!-- 메뉴 항목 3 -->
        <b-nav-item href="/openApi-main" class="menu-item font-weight-bolder h4">
          <li class="fas fa-plug"></li>
          <span class="nav-title-style">OPEN API</span>
        </b-nav-item>
      </b-navbar-nav>

      <!-- 우측에 사용자 메뉴 -->
      <b-navbar-nav>
        <b-nav-item-dropdown text="사용자" right>
          <template v-slot:button-content>
            <div class="d-inline-block font-weight-bolder">
              <li class="fas fa-user-circle"></li>
              {{ formData.username }}
            </div>
          </template>
          <div
            style="position: absolute;
            right: 5px;
            top: 0;
            z-index: 11;
            width: 160px;
            height: 150px;
            margin: 0 0 9px;
            padding: 5px 13px 3px 13px;
            border-radius: 4px;
            border: solid 1px #8d8d8d;
            background-color: #fff;"
          >
            <b-row>
              <b-col cols="12" class="m-0">
                <div class="h5 font-weight-bolder">
                  {{ formData.username }}
                  <b-button
                    v-if="formData.roleId === 'ROLE_ADMIN'"
                    variant="dark"
                    to="admin-list"
                    class="m-0"
                    style="padding: 3px;background-color: darkgrey !important;border-color: darkgray !important;font-size: 10px !important;"
                  >
                    <span class="align-middle">관리자</span>
                  </b-button>
                </div>
              </b-col>
              <b-col cols="6" class="pr-1 text-right" style="margin-top: 3px">
                <b-avatar v-b-modal.auth-profile v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary">
                  <feather-icon icon="UserIcon" />
                </b-avatar>
                <div>
                  내 정보
                </div>
              </b-col>
              <b-col cols="6" class="pl-1 text-left" style="margin-top: 3px">
                <b-avatar
                  v-b-modal.auth-bookmark
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                >
                  <feather-icon icon="HomeIcon" />
                </b-avatar>
                <div>
                  내 건물
                </div>
              </b-col>
              <b-col cols="12" class="text-center">
                <b-button variant="primary" size="sm" style="margin-top: 10px" @click="logout">
                  <feather-icon class="mr-50" icon="LogOutIcon" />
                  <span class="align-middle">로그아웃</span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar>
    <!--    회원정보 모달-->
    <b-modal id="auth-profile" centered size="lg" title="회원 정보" hide-footer>
      <auth-profile />
    </b-modal>
    <b-modal id="auth-bookmark" centered size="xl" title="내 건물" hide-footer>
      <auth-bookmark :user-id="formData.userId" />
    </b-modal>
  </div>
</template>
<script>
import {
  BAvatar,
  BDropdownItem,
  BNavbar,
  BNavbarBrand,
  BNavbarNav,
  BNavItem,
  BNavItemDropdown,
  BRow,
  BCol,
  BButton,
  BModal,
  VBModal,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AuthProfile from '~/auth/AuthProfile.vue';
import AuthBookmark from '~/auth/AuthBookmark.vue';

export default {
  components: {
    AuthBookmark,
    AuthProfile,
    BModal,
    BButton,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      formData: {
        username: '',
        current: '',
      },
    };
  },
  computed: {
    current() {
      return this.$route.name ? this.$route.name : 'building-service';
    },
  },
  async created() {
    this.formData = await this.$store.dispatch('auth/FETCH_AUTH_INFO', {
      userId: this.$store.state.auth.usrId,
    });

    // console.log(this.formData);
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/FETCH_AUTH_LOGOUT');
      // Redirect to login page
      await this.$router.push('/auth-login');
    },
    goto2DMap() {
      gis.search.goto2DMap(map);
      // this.$router.push({ name: 'building-service' });
    },
    goto3DMap() {
      gis.search.goto3DMap(map);
      // this.$router.push({ name: 'building-service-3d' });
    },
  },
};
</script>
<style scoped>
.naviArea {
  background-color: #f3f2f7 !important;
  padding: 5px 0px 5px 0px;
  height: 60px;
}
.bg-dark {
  background-color: #fff !important;
}
.brand-logo {
  width: 150px;
  height: 38px;
  margin: 0px 0px 10px 10px;
  object-fit: contain;
}

.menu-item {
  padding: 0.5rem 6rem; /* 메뉴 항목의 패딩 조절 */
  color: #000 !important; /* 글자색을 검정(#000)으로 설정 */
  padding: 0px 80px 10px 80px;
}

.menu-item:hover {
  background-color: #e7e3e3; /* 호버 상태 배경색 */
  color: #fff; /* 호버 상태 글자색 */
}

.align-v {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.nav-title-style {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4c4c4c;
}

.menu-item:hover {
  background-color: #fff;
}
</style>
